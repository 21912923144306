/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AreaItem } from '../models/area-item';
import { Customer } from '../models/customer';
import { EspVenue } from '../models/esp-venue';
import { EventFlight } from '../models/event-flight';

@Injectable({
  providedIn: 'root',
})
export class TeetimesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPccTeetimeList
   */
  static readonly GetPccTeetimeListPath = '/api/golftime/pccs/{venue}/teetimes/{date}';

  /**
   * Returns teetimes by club and vanue.
   *
   * Returns teetimes by club and venue
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPccTeetimeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccTeetimeList$Response(params: {
    venue: string;
    date: string;
  }): Observable<StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>> {

    const rb = new RequestBuilder(this.rootUrl, TeetimesService.GetPccTeetimeListPath, 'get');
    if (params) {
      rb.path('venue', params.venue, {});
      rb.path('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>;
      })
    );
  }

  /**
   * Returns teetimes by club and vanue.
   *
   * Returns teetimes by club and venue
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPccTeetimeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccTeetimeList(params: {
    venue: string;
    date: string;
  }): Observable<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }> {

    return this.getPccTeetimeList$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>) => r.body as { 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem })
    );
  }

  /**
   * Path part for operation linkPcc
   */
  static readonly LinkPccPath = '/api/golftime/links/pccs';

  /**
   * link pcc.
   *
   * link pcc
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkPcc()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  linkPcc$Response(params: {
    body: { 'username': string, 'password': string }
  }): Observable<StrictHttpResponse<{ 'msg'?: string, 'error_description'?: string, 'customer'?: Customer }>> {

    const rb = new RequestBuilder(this.rootUrl, TeetimesService.LinkPccPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'msg'?: string, 'error_description'?: string, 'customer'?: Customer }>;
      })
    );
  }

  /**
   * link pcc.
   *
   * link pcc
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkPcc$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  linkPcc(params: {
    body: { 'username': string, 'password': string }
  }): Observable<{ 'msg'?: string, 'error_description'?: string, 'customer'?: Customer }> {

    return this.linkPcc$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'msg'?: string, 'error_description'?: string, 'customer'?: Customer }>) => r.body as { 'msg'?: string, 'error_description'?: string, 'customer'?: Customer })
    );
  }

  /**
   * Path part for operation updateFriend
   */
  static readonly UpdateFriendPath = '/api/golftime/updates/friends';

  /**
   * update a friend.
   *
   * update a friend
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFriend()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateFriend$Response(params: {
    body: { 'customer'?: Customer, 'email': string, 'anrede': string, 'firstname': string, 'lastname': string, 'customer_id': string }
  }): Observable<StrictHttpResponse<{ 'msg'?: string, 'error_description'?: string, 'friend'?: Customer, 'customer'?: Customer }>> {

    const rb = new RequestBuilder(this.rootUrl, TeetimesService.UpdateFriendPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'msg'?: string, 'error_description'?: string, 'friend'?: Customer, 'customer'?: Customer }>;
      })
    );
  }

  /**
   * update a friend.
   *
   * update a friend
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFriend$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateFriend(params: {
    body: { 'customer'?: Customer, 'email': string, 'anrede': string, 'firstname': string, 'lastname': string, 'customer_id': string }
  }): Observable<{ 'msg'?: string, 'error_description'?: string, 'friend'?: Customer, 'customer'?: Customer }> {

    return this.updateFriend$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'msg'?: string, 'error_description'?: string, 'friend'?: Customer, 'customer'?: Customer }>) => r.body as { 'msg'?: string, 'error_description'?: string, 'friend'?: Customer, 'customer'?: Customer })
    );
  }

  /**
   * Path part for operation bookPccTeetime
   */
  static readonly BookPccTeetimePath = '/api/golftime/books/{venue}/pccs/{date}/teetimes/{time}';

  /**
   * book a teetime.
   *
   * book a teetime
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookPccTeetime()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bookPccTeetime$Response(params: {
    venue: string;
    date: string;
    time: string;
    body: { 'friendIds': string }
  }): Observable<StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>> {

    const rb = new RequestBuilder(this.rootUrl, TeetimesService.BookPccTeetimePath, 'post');
    if (params) {
      rb.path('venue', params.venue, {});
      rb.path('date', params.date, {});
      rb.path('time', params.time, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>;
      })
    );
  }

  /**
   * book a teetime.
   *
   * book a teetime
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookPccTeetime$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bookPccTeetime(params: {
    venue: string;
    date: string;
    time: string;
    body: { 'friendIds': string }
  }): Observable<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }> {

    return this.bookPccTeetime$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>) => r.body as { 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem })
    );
  }

  /**
   * Path part for operation deletePccTeetime
   */
  static readonly DeletePccTeetimePath = '/api/golftime/deletes/{venue}/pccs/{date}/teetimes/{booking}';

  /**
   * delete a booking.
   *
   * delete a booking
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePccTeetime()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  deletePccTeetime$Response(params: {
    venue: string;
    date: string;
    booking: string;
    body: { 'load_teetimes'?: boolean }
  }): Observable<StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>> {

    const rb = new RequestBuilder(this.rootUrl, TeetimesService.DeletePccTeetimePath, 'post');
    if (params) {
      rb.path('venue', params.venue, {});
      rb.path('date', params.date, {});
      rb.path('booking', params.booking, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>;
      })
    );
  }

  /**
   * delete a booking.
   *
   * delete a booking
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePccTeetime$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  deletePccTeetime(params: {
    venue: string;
    date: string;
    booking: string;
    body: { 'load_teetimes'?: boolean }
  }): Observable<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }> {

    return this.deletePccTeetime$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem }>) => r.body as { 'teetimes'?: Array<EventFlight>, 'venue'?: AreaItem })
    );
  }

  /**
   * Path part for operation getPccVenueList
   */
  static readonly GetPccVenueListPath = '/api/golftime/pcc/venues';

  /**
   * Returns a list of venues by club.
   *
   * Returns a list of venues by club
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPccVenueList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccVenueList$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'venues'?: Array<EspVenue> }>> {

    const rb = new RequestBuilder(this.rootUrl, TeetimesService.GetPccVenueListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'venues'?: Array<EspVenue> }>;
      })
    );
  }

  /**
   * Returns a list of venues by club.
   *
   * Returns a list of venues by club
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPccVenueList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccVenueList(params?: {
  }): Observable<{ 'venues'?: Array<EspVenue> }> {

    return this.getPccVenueList$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'venues'?: Array<EspVenue> }>) => r.body as { 'venues'?: Array<EspVenue> })
    );
  }

}
