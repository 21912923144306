import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../shared/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy {
  public menuDataModel = new BehaviorSubject<any[]>(null);
  public MenuService$: Observable<any[]> = this.menuDataModel.asObservable();

  private allPages = [
    {
      title: 'menu.item.home',
      url: '/home',
      icon: 'icon-home',
      roles: [''],
      selected: true
    },
    {
      title: 'menu.item.series',
      url: '/series',
      icon: 'icon-trophy',
      roles: ['ROLE_SERIES'],
      selected: false
    },
    {
      title: 'menu.item.challenges',
      url: '/challenges',
      icon: 'icon-trophy-award',
      roles: ['ROLE_SERIES', 'ROLE_CHALLENGES'],
      selected: false
    },
    {
      title: 'menu.item.payments',
      url: '/payments',
      icon: 'icon-coin',
      roles: ['ROLE_CHALLENGES'],
      selected: false
    },
    {
      title: 'menu.item.shop',
      url: '/shop',
      icon: 'icon-gift',
      roles: [''],
      selected: false
    },
    {
      title: 'menu.item.teetimes',
      url: '/teetimes',
      icon: 'icon-timer',
      roles: ['', 'ROLE_TEETIME'],
      selected: false
    },
    // {
    //   title: 'Events',
    //   url: '/events/list',
    //   icon: 'icon-calendar',
    //   roles: ['', 'ROLE_EVENTS'],
    //   selected: false
    // },
    {
      title: 'menu.item.profile',
      url: '/profile',
      icon: 'icon-account',
      roles: [''],
      selected: false
    },
    {
      title: 'menu.item.sponsors',
      url: '/sponsors',
      icon: 'icon-polymer',
      roles: [''],
      selected: false
    },
    {
      title: 'menu.item.contact',
      url: '/contact',
      icon: 'icon-email-outline',
      roles: [''],
      selected: false
    }
  ];
  private appPages: any[];

  constructor(
    private _authService: AuthService,
    private router: Router
  ) { }

  loadMenu(isLoggedIn = false) {
    this.appPages = JSON.parse(sessionStorage.getItem('appMenu'));
    let allPages = JSON.parse(sessionStorage.getItem('allMenu'));

    if (!allPages) {
      allPages = this.allPages;
      sessionStorage.setItem('allMenu', JSON.stringify(this.allPages));
    }
    if (!this.appPages) {
      this.appPages = allPages;
      sessionStorage.setItem('appMenu', JSON.stringify(this.appPages));
    }
    if (isLoggedIn) {
      if (!this._authService.containRole('ROLE_SERIES')) {
        if (!this._authService.containRole('ROLE_CHALLENGES')) {
          this.appPages = allPages.filter(x => x.roles.indexOf('ROLE_SERIES') === -1);
        } else {
          this.appPages = allPages.filter(x => x.roles.indexOf('ROLE_CHALLENGES') !== -1 || x.roles.indexOf('ROLE_SERIES') === -1);
        }
      } else {
        this.appPages = allPages.filter(x => (x.roles.indexOf('ROLE_SERIES') !== -1 || x.roles.indexOf('') !== -1))
      }
    }
    this.menuDataModel.next(this.appPages);
  }

  findByUrl(url: string): any {
    let item = null;
    this.appPages.forEach(x => {
      if (x.url === url) {
        item = x;
      }
    });

    const urlItems = url.split('/');

    let shortUrl = '';
    for (let index = 0; index < (urlItems.length-1); index++) {
      const element = urlItems[index];
      shortUrl = '/' + element;
    }
    if (!item) {
      shortUrl = shortUrl === '' ? '/home' : shortUrl;
      item = this.findByUrl(shortUrl);
    }
    return item;
  }

  navigate(url: string) {
    if(!this.appPages) {
      const isLoggedIn = this._authService.isLoggedIn();
      // this.menuDataModel.next(null);
      this.loadMenu(isLoggedIn);
    }
    const item = this.findByUrl(url);
    this.setSelectedMenu(item);
    this.router.navigate([url]);
  }

  setSelectedMenu(item: any) {
    this.appPages.forEach(x => {
      x.selected = false;
      if (x.title === item.title) {
        x.selected = true;
      }
    });
    this.allPages.forEach(x => {
      x.selected = false;
      if (x.title === item.title) {
        x.selected = true;
      }
    });
    sessionStorage.setItem('appMenu', JSON.stringify(this.appPages));
    sessionStorage.setItem('allMenu', JSON.stringify(this.allPages));
    this.menuDataModel.next(this.appPages);
  }

  ngOnDestroy() {
    this.menuDataModel.next(null);
    this.menuDataModel.complete();
  }
}
