import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MenuService } from 'src/app/services/menu.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _authService: AuthService,
    private router: Router,
    private menuService: MenuService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // const item = this.menuService.findByUrl(state.url);
    // console.dir(state.url);
    // console.dir(item);
    if (this._authService.isLoggedIn()) {
      // if (!item) {
      //   this.router.navigate(['/home']);
      //   return false;
      // }
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
