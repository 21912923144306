import { Injectable, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  isLoading = new EventEmitter<boolean>();
  private isLoading$ = false;

  public refreshDataModel = new BehaviorSubject<number>(null);
  public RefreshService$: Observable<number> = this.refreshDataModel.asObservable();

  constructor() {
    this.isLoading.subscribe(e => {
      // console.dir(moment() + ': isLoading: ' + e);
      this.isLoading$ = e;
    });
  }

  getIsLoading() {
    return this.isLoading$;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading.emit(isLoading);
  }
}
