import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SeriesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { environment } from 'src/environments/environment';

declare var Stripe;

@Component({
  selector: 'app-stripe-java-script',
  templateUrl: './stripe-java-script.component.html',
  styleUrls: ['./stripe-java-script.component.scss'],
})
export class StripeJavaScriptComponent implements OnInit {

  stripe: any;
  products: any[] = [];
  isHeaderVisible = false;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private _seriesService: SeriesService,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService
  ) { }

  ngOnInit() {
    if (this._authService.containRole('ROLE_SERIES')) {
      this.router.navigateByUrl('home');
    } else {
      this.setupProducts();
    }
  }

  setupProducts() {

    const params: any = {};
    this._seriesService.getGtProducts(params).subscribe(prods => {
      prods.products.forEach(prod => {
        let image_url = 'assets/imgs/background/21.jpg';
        if(prod['image']) {
          image_url = environment.api.host + prod['image']['public_urls']['big']['url'];
        }
        const item = {
          "id": prod.id,
          "image": image_url,
          "title": prod.name,
          "subtitle": prod.aditional_name,
          "button": 'page.payment.button.anmelden',
          "price": prod.price + ' CHF'
        };
        this.products.push(item);
      });
    });
  }

  setupStripe(item){

    this._seriesService.getStripeKey().subscribe(stripe => {
      this.stripe = Stripe(stripe);
      this.getStripeSession(item);
    });
  }

  onItemClickFunc(item: any) {
    if (event) {
      event.stopPropagation();
    }
    this.pay(item);
  }

  pay(item) {
    // stripe with session
    this.setupStripe(item);
  }

  getStripeSession(item) {
    const params = {
      product: item.id
    };
    this._seriesService.getStripeSession(params).subscribe(stripeSession => {
      if (stripeSession.reload === true) {
        this._authService.refreshToken().subscribe(x => {
          location.reload();
        });
      } else {
        this.stripe
          .redirectToCheckout({
            sessionId: stripeSession.session.id, // generated on the symfony (currently on postman)
            // lineItems: [
            //   // Replace with the ID of your price
            //   {price: 'price_1HvvXYDDMgNoAxhI6LFUwAru', quantity: 1},
            // ],
            // mode: 'subscription',
            // successUrl: 'http://localhost:8100/payments/success',
            // cancelUrl: 'http://localhost:8100/payments/cancel',
          })
          .then(result => {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `result.error.message`.
            // this.toastCtrl.presentToast('Error redirectToCheckout: message: ' + result.error.message, 'dark', 10000);
          });
      }
    });
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }
}
