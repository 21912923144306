import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventAttendee2 } from './core/api/models';
import { Photo } from './photo.service';

@Injectable({
  providedIn: 'root'
})
export class UploadsDataService implements OnDestroy {
  public personalDataModel = new BehaviorSubject<EventAttendee2>(null);
  public UploadsDataService$: Observable<EventAttendee2> = this.personalDataModel.asObservable();

  constructor() {}
  ngOnDestroy() {
    this.personalDataModel.next(null);
    this.personalDataModel.complete();
  }
  setData(photo: EventAttendee2): void {
    this.personalDataModel.next(photo);
  }
}
