import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap, finalize, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { LoadingSpinnerService } from '../loading-spinner.service';
import * as moment from 'moment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private loadingService: LoadingSpinnerService,
    private adalService: AuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const started = Date.now();
    let ok: string;
    this.loadingService.setIsLoading(true);
    // if (req.url.indexOf('/public/') === -1) {
      req = this.adalService.addHeader(req);
    // }

    // extend server response observable with logging
    return next.handle(req).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        event => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
        // Operation failed; error is an HttpErrorResponse
        error => (ok = 'failed')
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
        // this.messenger.add(msg);
        const refresh = sessionStorage.getItem('refresh-token');
        if (refresh !== 'yes') {
          this.loadingService.setIsLoading(false);
        }
      })
    );
  }
}
