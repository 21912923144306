import { Component, OnInit } from '@angular/core';
import { SponsorsService } from 'src/app/services/sponsors.service';

@Component({
  selector: 'gt-header-sponsor',
  templateUrl: 'header-sponsor.page.html',
  styleUrls: ['header-sponsor.page.scss'],
})
export class HeaderSponsorPage implements OnInit {
  
  public slides: any[] = [];
  constructor(
    private _sponsorService: SponsorsService
  ) { }

  ngOnInit() {

    this.initDefaultHeader();
    this._sponsorService.sponsorsService$.subscribe(slides => {
      if (slides === null || !slides) {
        this._sponsorService.loadSponsors();
      } else {
        if (slides.length > 0) {
          this.slides = slides;
        }
      }
    });
  }

  initDefaultHeader() {
    this.slides.push({
      headerImage: '/assets/imgs/background/maxresdefault.jpg',
      title: '',
      subtitle: '',
      link: null
    });
    this.slides.push({
      headerImage: '/assets/imgs/background/26_bw1.jpg',
      title: '',
      subtitle: '',
      link: null
    });
  }
}
