import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Platform } from '@ionic/angular';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-auth-callback-page',
  templateUrl: './auth-callback-page.component.html',
  styleUrls: ['./auth-callback-page.component.scss'],
})
export class AuthCallbackPageComponent implements OnInit {

  constructor(
    public router: Router,
    private platform: Platform,
    private _authService: AuthService
  ) {
  }

  ngOnInit() {
    this.platform.ready().then(async () => {
      this.checkAccessToken();
    });
    // this.router.navigateByUrl('/home');
  }

  checkAccessToken() {
    const access_token = this._authService.getToken();
    if (!access_token) {
      setTimeout(() => {
          this.checkAccessToken();
      }, 10);
    } else {
      // this.router.navigateByUrl('/home');
      location.href = '/home';
    }
  }

}
