import { Component, OnInit } from '@angular/core';
import localeDe from '@angular/common/locales/de-CH';

import { registerLocaleData } from '@angular/common';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SeriesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { environment } from 'src/environments/environment';
import { TokenHelperService } from 'src/app/shared/auth/token-helper-service.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'gt-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {

  data = {
    sponsors: {
      gold: [],
      silver: [],
      bronze: []
    }
  };
  selectedItem = null;
  isHeaderVisible = false;

  constructor(
    private _authService: AuthService,
    private _seriesService: SeriesService,
    private jwt_decode: TokenHelperService,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.setupSponsors();
  }

  setupSponsors() {
    
    this.data.sponsors = {
      gold: [],
      silver: [],
      bronze: []
    }
    const params: any = {
      position: 'sponsor-page'
    };
    this._seriesService.getGtSponsors(params).subscribe(prods => {
      const sponsors_gold = prods['sponsor.sponsor-gold.sponsor-page'];
      const sponsors_silver = prods['sponsor.sponsor-page.sponsor-silver'];
      const sponsors_bronze = prods['sponsor.sponsor-bronze.sponsor-page'];
      if (sponsors_gold) {
        sponsors_gold.forEach(prod => {
          let image_url = 'assets/imgs/background/21.jpg';
          if(prod['image']) {
            image_url = environment.api.host + prod['image']['public_urls']['big']['url'];
          }
          const item = {
            "id": prod.id,
            "image": image_url,
            "title": prod.name,
            "subtitle": prod.aditional_name
          };
          this.data.sponsors.gold.push(item);
        });
      }
      if (sponsors_silver) {
        sponsors_silver.forEach(prod => {
          let image_url = 'assets/imgs/background/21.jpg';
          if(prod['image']) {
            image_url = environment.api.host + prod['image']['public_urls']['big']['url'];
          }
          const item = {
            "id": prod.id,
            "image": image_url,
            "title": prod.name,
            "subtitle": prod.aditional_name
          };
          this.data.sponsors.silver.push(item);
        });
      }
      if (sponsors_bronze) {
        sponsors_bronze.forEach(prod => {
          let image_url = 'assets/imgs/background/21.jpg';
          if(prod['image']) {
            image_url = environment.api.host + prod['image']['public_urls']['big']['url'];
          }
          const item = {
            "id": prod.id,
            "image": image_url,
            "title": prod.name,
            "subtitle": prod.aditional_name
          };
          this.data.sponsors.bronze.push(item);
        });
      }
    });
  }
  
  doRefresh(event) {
    event.target.complete();
    this.setupSponsors();
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }

}
