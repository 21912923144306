import { Injectable } from '@angular/core';
import {
  HttpRequest
} from '@angular/common/http';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { switchMap, tap, catchError } from 'rxjs/operators';
import { LoginService } from 'src/app/services/core/api/services/login.service';
import { TokenHelperService } from './token-helper-service.service';
import { Customer } from 'src/app/services/core/api/models';


@Injectable()
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  public loggedIn$: Observable<boolean> = this.loggedIn.asObservable();

  private customer = new BehaviorSubject<Customer>(null);
  public customer$: Observable<Customer> = this.customer.asObservable();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private tokenHelperService: TokenHelperService
  ) {
    if (!this.isLoggedIn()) {
      this.startAuthentication();
    } else {
      this.loggedIn.next(true);
    }
  }

  public isLoggedIn(): boolean {
    return this.hasAccessToken();
  }

  public signout(): void {
    localStorage.clear();
    this.loggedIn.next(false);
    this.router.navigate(['login']);
  }

  public hasAccessToken() {
    const token = localStorage.getItem('access_token');

    if (token !== null && token !== undefined) {
      return true;
    }
    return false;
  }

  public startAuthentication(): any {
    this.loggedIn.next(false);
    // this.router.navigate(['login']);
  }

  public tokenWillNotExpire() {
    const expiresAt = localStorage.getItem('expires_at');
    const now = new Date();
    if (expiresAt == null || (expiresAt && parseInt(expiresAt, 10) < (now.getTime() + 300000))) {
      return false;
    }
    return true;
  }

  public refreshTokenStillValid() {
    const expiresAt = localStorage.getItem('refresh_token_exp');
    const now = new Date();
    if (expiresAt == null || (expiresAt && parseInt(expiresAt, 10) < now.getTime())) {
      return false;
    }
    return true;
  }

  public refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    const model = {
      body: {
        refresh_token: refreshToken
      }
    };

    return this.loginService.doRefreshTokenPost(model).pipe(
      tap(this.SetToken.bind(this))
    );
  }

  public SetToken(data: any): any {
    // const data = JSON.parse(JSON.parse(token));

    if (data.refresh_token !== null && data.refresh_token !== undefined) {
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('refresh_token_exp', '' + (Date.now() + data.refresh_token_expires_in * 1000));
    }

    if (data.access_token !== null && data.access_token !== undefined) {
      localStorage.setItem('adal.access.token.key', data.access_token);
      localStorage.setItem('id_token', data.id_token);
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('expires_at', '' + (Date.now() + (data.expires_in * 1000)));
      localStorage.setItem('id_token_expires_at', '' + (Date.now() + data.expires_in * 1000));
      localStorage.setItem('access_token_stored_at', '' + (Date.now()));
      this.loggedIn.next(true);
      this.setCustomer(data.customer);
    }
  }

  public setCustomer(customer: any): any {
    if (customer !== null && customer !== undefined) {
      localStorage.setItem('customer', JSON.stringify(customer));
      this.customer.next(customer);
    }
  }

  public getCustomer(): Customer {
    let customer = null;
    const customerStr = localStorage.getItem('customer');
    if (customerStr) {
      customer = JSON.parse(customerStr);
    }
    return customer;
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  public getRefreshToken(): string {
    return localStorage.getItem('refresh_token');
  }

  public getRoles(): Array<string> {
    const token = this.getToken();
    const decoded = this.tokenHelperService.parseJwt(token);
    const clientId = decoded.azp;
    let roles = [];
    if (decoded.resource_access[clientId]) {
      roles = decoded.resource_access[clientId].roles;
    }
    return roles;
  }

  public getAttributes(): any {
    const token = this.getToken();
    const decoded = this.tokenHelperService.parseJwt(token);
    return decoded;
  }

  public getAttribute(key: string) {
    const attr = this.getAttributes();
    if (attr[key]) {
      return attr[key];
    }
    return null;
  }

  public containRole(role: string) {
    const roles = this.getRoles();
    const index = roles.indexOf(role);
    return index !== -1;
  }

  public getIdToken(): string {
    return localStorage.getItem('id_token');
  }

  public addHeader(req: HttpRequest<any>): HttpRequest<any> {
    const lang = localStorage.getItem('currentLanguage');
    const setHeaders: any = {};
    if (this.getToken()) {
      setHeaders.Authorization = 'Bearer ' + this.getToken();
    }
    setHeaders.Locale = '' + lang;
    req = req.clone({
      setHeaders: setHeaders
    });

    return req;
  }
}
