import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { EspVenue, Organization } from '../services/core/api/models';

@Injectable({
  providedIn: 'root'
})
export class ClubSelectionService {

    private club = new BehaviorSubject<Organization>(null);
    public club$: Observable<Organization> = this.club.asObservable();

    private venue = new BehaviorSubject<EspVenue>(null);
    public venue$: Observable<EspVenue> = this.venue.asObservable();

    private clubs = [
        { id: '0410001', name: 'Ascona' },
        { id: '0410002', name: 'Bad Ragaz' },
        { id: '0410003', name: 'Basel' },
        { id: '0410004', name: 'Blumisberg' },
        { id: '0410005', name: 'Bodensee Weissensberg' },
        { id: '0410006', name: 'Les Bois' },
        { id: '0410007', name: 'Bonmont' },
        { id: '0410008', name: 'Bossey' },
        { id: '0410009', name: 'Breitenloo' },
        { id: '0410010', name: 'Crans-sur-Sierre' },
        { id: '0410011', name: 'Davos' },
        { id: '0410012', name: 'Domaine Impérial' },
        { id: '0410013', name: 'Engadine' },
        { id: '0410014', name: 'Erlen' },
        { id: '0410015', name: 'Esery' },
        { id: '0410016', name: 'Genève' },
        { id: '0410017', name: 'Hittnau-Zürich' },
        { id: '0410018', name: 'Interlaken' },
        { id: '0410019', name: 'La Largue' },
        { id: '0410020', name: 'Lausanne' },
        { id: '0410021', name: 'Lenzerheide' },
        { id: '0410022', name: 'Lucerne' },
        { id: '0410023', name: 'Lugano' },
        { id: '0410024', name: 'Maison Blanche' },
        { id: '0410025', name: 'Payerne' },
        { id: '0410026', name: 'Neuchâtel' },
        { id: '0410027', name: 'Montreux' },
        { id: '0410028', name: 'OSGC' },
        { id: '0410030', name: 'Sedrun' },
        { id: '0410031', name: 'Schönenberg' },
        { id: '0410032', name: 'Verbier' },
        { id: '0410033', name: 'Villars' },
        { id: '0410034', name: 'Wallenried' },
        { id: '0410035', name: 'Zürich-Zumikon' },
        { id: '0410037', name: 'Sempachersee' },
        { id: '0410038', name: 'Gstaad' },
        { id: '0410039', name: 'Entfelden' },
        { id: '0410040', name: 'Ennetsee' },
        { id: '0410041', name: 'Alvaneu Bad' },
        { id: '0410042', name: 'Domat / Ems' },
        { id: '0410043', name: 'Schinznach Bad' },
        { id: '0410044', name: 'Arosa' },
        { id: '0410045', name: 'Bürgenstock' },
        { id: '0410046', name: 'Gruyère' },
        { id: '0410047', name: 'Dolder' },
        { id: '0410048', name: 'Riederalp' },
        { id: '0410049', name: 'Sierre' },
        { id: '0410050', name: 'Vulpera' },
        { id: '0410051', name: 'Appenzell' },
        { id: '0410052', name: 'Bubikon' },
        { id: '0410053', name: 'Wylihof' },
        { id: '0410054', name: 'Küssnacht' },
        { id: '0410055', name: 'Engelberg-Titlis' },
        { id: '0410056', name: 'Flühli Sörenberg' },
        { id: '0410057', name: 'Gotthard Realp' },
        { id: '0410058', name: 'Goldenberg' },
        { id: '0410059', name: 'Nuolen' },
        { id: '0410060', name: 'Les Coullaux' },
        { id: '0410061', name: 'Domaine du Brésil' },
        { id: '0410062', name: 'Lavaux' },
        { id: '0410064', name: 'Sion' },
        { id: '0410065', name: 'Source du Rhône' },
        { id: '0410066', name: 'Limpachtal' },
        { id: '0410067', name: 'Emmental' },
        { id: '0410068', name: 'Ybrig' },
        { id: '0410069', name: 'Heidental' },
        { id: '0410070', name: 'Thunersee' },
        { id: '0410071', name: 'Aaretal' },
        { id: '0410072', name: 'La Côte' },
        { id: '0410073', name: 'Lipperswil' },
        { id: '0410074', name: 'Waldkirch' },
        { id: '0410075', name: 'Losone' },
        { id: '0410076', name: 'Lägern' },
        { id: '0410077', name: 'Vuissens' },
        { id: '0410080', name: 'Leuk' },
        { id: '0410082', name: 'Unterengstringen' },
        { id: '0410083', name: 'Engadine Golf Club' },
        { id: '0410084', name: 'Bern' },
        { id: '0410085', name: 'Rastenmoos' },
        { id: '0410086', name: 'Matterhorn' },
        { id: '0410087', name: 'Winterberg' },
        { id: '0410088', name: 'Kyburg' },
        { id: '0410089', name: 'Klosters' },
        { id: '0410090', name: 'Heidiland' },
        { id: '0410092', name: 'Fricktal' },
        { id: '0410093', name: 'Brigels' },
        { id: '0410094', name: 'Axenstein' },
        { id: '0410095', name: 'Oberkirch' },
        { id: '0410096', name: 'Gams' },
        { id: '0410097', name: 'Rheinfelden' },
        { id: '0410098', name: 'Weid Hauenstein' },
        { id: '0410099', name: 'Sagogn' },
        { id: '0410100', name: 'Laufental' },
        { id: '0410101', name: 'Augwil' },
        { id: '0412000', name: 'ASGI' },
        { id: '0417000', name: 'ASG Migros' },
        { id: '0497719', name: 'Markgräflerland, GC' },
        { id: '0497734', name: 'Obere Alp, GC' },
        { id: '0497742', name: 'Rheinblick, GC' }
      ];

    constructor() {
    }

    public setClub(club: Organization) {
        if (club !== null && club !== undefined) {
            localStorage.setItem('selectedClub', JSON.stringify(club));
            this.club.next(club);
        }
    }

    public getClub(): Organization {
        let club = null;
        const clubStr = localStorage.getItem('selectedClub');
        if (clubStr) {
            club = JSON.parse(clubStr);
        }
        return club;
    }

    public getClubs(): any[] {
        const golfclubs = [];
        this.clubs.sort((a,b) => a.name.localeCompare(b.name));
        golfclubs.push({
            id: '0000000',
            name: 'Unbekannt'
        });
        this.clubs.forEach(element => {
            golfclubs.push(element);
        });
        return golfclubs;
    }

    public findClubByCustomerId(customer_id) {
        if (!customer_id || customer_id.length < 7) {
            return null;
        }
        const clubid = customer_id.substring(0, 7);
        return this.clubs.find(x => x.id === clubid);
    }

    public setVenue(venue: EspVenue) {
        if (venue !== null && venue !== undefined) {
            localStorage.setItem('selectedVenue', JSON.stringify(venue));
            this.venue.next(venue);
        }
    }

    public removeVenue() {
        localStorage.removeItem('selectedVenue');
        this.venue.next(null);
    }

    public getVenue(): EspVenue {
        let venue = null;
        const venueStr = localStorage.getItem('selectedVenue');
        if (venueStr) {
            venue = JSON.parse(venueStr);
        }
        return venue;
    }
}
