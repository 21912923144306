import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';

import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ToastService } from 'src/app/services/toast-service';
import { LoadingSpinnerService } from '../loading-spinner.service';
import * as moment from 'moment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private refreshTokenInProgress = false;

    constructor(
        private authenticationService: AuthService,
        private toastCtrl: ToastService,
        private loadingService: LoadingSpinnerService
        /*private notificationService: AlertService*/) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this.notificationService.reset();
        // this.loadingService.setIsLoading(true);
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                // if (error && error.status === 403) {
                //     this.authenticationService.signout();
                //     location.reload();
                //     this.refreshTokenSubject.next(null);
                // }
                // this.loadingService.setIsLoading(true);
                if (error && (error.status === 401 || error.status === 403)) {
                    // console.dir('error.status === 401 || error.status === 403');
                    // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                    if (this.refreshTokenInProgress) {
                        // console.dir('refreshTokenInProgress');
                        // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                        // which means the new token is ready and we can retry the request again
                        if (req.url.endsWith('/refreshes/tokens')) {
                            // console.dir('req.url.endsWith(/refreshes/tokens)');
                            this.authenticationService.signout();
                            // location.reload();
                            // this.refreshTokenSubject.next(null);
                            return next.handle(req);
                        } else {
                            // console.dir('refreshes/tokens::else');
                            return this.refreshTokenSubject.pipe(
                                filter(result => result !== null),
                                take(1),
                                switchMap(() => {

                                    return next.handle(
                                        this.authenticationService.addHeader(
                                            req.clone()));
                                }));
                        }
                    } else {
                        // console.dir('refreshTokenInProgress::else');
                        this.refreshTokenInProgress = true;
                        this.refreshTokenSubject.next(null);

                        return this.authenticationService.refreshToken().pipe(
                            switchMap((r) => {
                                if (!r || r.error) {
                                    // console.dir('!r || r.error');
                                    // console.dir(r.error);
                                    this.authenticationService.signout();
                                    // location.reload();
                                    return next.handle(req);
                                } else {
                                    // console.dir(moment() + ': else:::!r || r.error');
                                    this.authenticationService.SetToken(r);
                                    this.refreshTokenSubject.next(r);
                                    this.loadingService.setIsLoading(true);
                                    sessionStorage.setItem('refresh-token', 'yes');
                                    return next.handle(
                                        this.authenticationService.addHeader(
                                            req.clone()));
                                }
                            }),
                            catchError(e => {
                                if (e.status === 401 || e.status === 403) {
                                    // console.dir('e.status === 401 || e.status === 403');
                                    this.authenticationService.signout();
                                    // location.reload();
                                }
                                return next.handle(req);
                            }),
                            // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                            // for the next time the token needs to be refreshed
                            finalize(() => {
                                // console.dir(moment() + ': finalize');
                                this.refreshTokenInProgress = false;
                                this.loadingService.setIsLoading(false);
                                sessionStorage.setItem('refresh-token', 'no');
                            })
                        );
                    }
                }
                if (error.message) {
                    // this.notificationService.sendMessage(error.error.message);
                    if(error.message.length > 0 && error.message.indexOf('assets/i18n') === -1) {
                        this.toastCtrl.presentToast(error.message, 'danger', 6000);
                    }
                }
                return throwError(error);
            }));
    }
}
