import { Injectable } from '@angular/core';

@Injectable()
export class TokenHelperService {
  constructor(
  ) { }

  getCurrentUserEmail(token): string {
    return this.parseJwt(token).upn;
  }

  getCurrentUserCustomerId(token): any {
    let subjectIdToken = this.parseJwt(token).subjectId;
    if (subjectIdToken && !Array.isArray(subjectIdToken)) {
      subjectIdToken = subjectIdToken.split(',');
    }
    return subjectIdToken;
  }

  parseJwt(token) {
    if (!token) {
      return {
        subjectId: '',
        role: [],
        upn: ''
      };
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  getCurrentUserRoles(token) {
    return this.parseJwt(token).role;
  }

  getCiid(token) {
    return this.parseJwt(token).ciid;
  }
}
