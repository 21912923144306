import { Component, NgZone, OnInit } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Plugins } from '@capacitor/core';

import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { AuthService } from './shared/auth/auth.service';
import { TokenHelperService } from './shared/auth/token-helper-service.service';
import { MenuService } from './services/menu.service';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { MatomoService } from './services/matomo.service';
import { ToastService } from './services/toast-service';
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';

const { App } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public allPages = [];
  public appPages = [];
  public username = '';
  public isLoggedIn = false;

  public language = '';

  headerMenuItem = {
    'background': 'assets/imgs/background/maxresdefault.jpg',
    // 'image': 'assets/imgs/logo/iphone_screen_logo_inverse.png',
  };

  private browser = null;
  private inAppBrowserOptions: InAppBrowserOptions = {
    location : 'yes', // Or 'no'
    hidden : 'no', // Or  'yes'
    clearcache : 'yes',
    clearsessioncache : 'yes',
    zoom : 'no', // Android only ,shows browser zoom controls
    hardwareback : 'yes',
    mediaPlaybackRequiresUserAction : 'no',
    shouldPauseOnSuspend : 'no', // Android only
    closebuttoncaption : 'Close', // iOS only
    disallowoverscroll : 'no', // iOS only
    toolbar : 'yes', // iOS only
    enableViewportScale : 'no', // iOS only
    allowInlineMediaPlayback : 'no', // iOS only
    presentationstyle : 'pagesheet', // iOS only
    fullscreen : 'yes', // Windows only
  };

  constructor(
    private zone: NgZone,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private _authService: AuthService,
    private router: Router,
    private iab: InAppBrowser,
    private jwt_decode: TokenHelperService,
    private menuService: MenuService,
    private translationService: TranslateService,
    private menu: MenuController,
    private matomoService: MatomoService,
    private deploy: Deploy,
    private toastCtrl: ToastService,
  ) {
    this.initializeApp();
  }

  async configureDeploy() {
    const config = {
      'appId': '3ccfa74d',
      'channel': 'Production'
    }
    await this.deploy.configure(config);
   }

  async performManualUpdate() {
    const update = await this.deploy.checkForUpdate()
    if (update.available){
      await this.deploy.downloadUpdate((progress) => {
        // console.log(progress);
      });
      await this.deploy.extractUpdate((progress) => {
        // console.log(progress);
      });
      await this.deploy.reloadApp();
      this.toastCtrl.presentToast('update successfull!');
    }
   }

  initializeApp() {
    this.initPush();
    this.configureDeploy();
    this.performManualUpdate();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.getDeviceLanguage();
    });
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          const slug = data.url.split(".golf").pop();
          if (slug) {
            this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
    });
  }

  initPush() {
    // // to check if we have permission
    // this.pushNotification.hasPermission()
    // .then((res: any) => {

    //   if (res.isEnabled) {
    //     console.log('We have permission to send push notifications');
    //   } else {
    //     console.log('We do not have permission to send push notifications');
    //   }

    // });

    // // Create a channel (Android O and above). You'll need to provide the id, description and importance properties.
    // this.pushNotification.createChannel({
    //   id: "testchannel1",
    //   description: "My first test channel",
    //   // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
    //   importance: 3,
    //   //badge is used to if badge appears on the app icon see https://developer.android.com/reference/android/app/NotificationChannel.html#setShowBadge(boolean).
    //   //false = no badge on app icon.
    //   //true = badge on app icon
    //   badge: false
    // }).then(() => console.log('Channel created'));

    // // Delete a channel (Android O and above)
    // this.pushNotification.deleteChannel('testchannel1').then(() => console.log('Channel deleted'));

    // // Return a list of currently configured channels
    // this.pushNotification.listChannels().then((channels) => console.log('List of channels', channels))

    // // to initialize push notifications

    // const options: PushOptions = {
    //   android: {},
    //   ios: {
    //       alert: 'true',
    //       badge: true,
    //       sound: 'false'
    //   },
    //   windows: {},
    //   browser: {
    //       pushServiceURL: 'http://push.api.phonegap.com/v1/push'
    //   }
    // }

    // const pushObject: PushObject = this.pushNotification.init(options);
    // pushObject.on('notification').subscribe((notification: any) => console.log('Received a notification', notification));
    // pushObject.on('registration').subscribe((registration: any) => console.log('Device registered', registration));
    // pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  }
  
  _initTranslate(language) {
    // Set the default language for translation strings, and the current language.
    this.translationService.setDefaultLang(environment.defaultLang);
    if (language) {
      this.language = language;
    }
    else {
      // Set your language here
      this.language = environment.defaultLang;
    }
    localStorage.setItem('currentLanguage', this.language);
    this.translationService.use(this.language);
  }

  getDeviceLanguage() {
    if (window.Intl && typeof window.Intl === 'object') {
      this._initTranslate(navigator.language)
    }
    else {
      this._initTranslate(environment.defaultLang)
    }
  }

  ngOnInit() {
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.menuService.MenuService$.subscribe(menus => {
      if (!menus) {
        // this.menuService.loadMenu();
        menus = [];
      }
      this.appPages = menus;
    });
    this.checkLogin();
    this.initMatomo();
  }

  initMatomo() {
    this.router.events.subscribe((event: RouterEvent) => {

      if (event instanceof NavigationStart) {
        // Show loading indicator
        let ignoreUrl = false;
        let url = event.url;
        if (url === '' || url === '/') {
          url = '/';
        }
        if (!ignoreUrl) {
          sessionStorage.setItem('currentRoute', url);
        }
      }
      if (event instanceof NavigationEnd) {
        this.matomoService.sendEvent(); // alle URL changes warden gesendet
      }
    });
  }

  private checkLogin() {
    this._authService.loggedIn$.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
      if (!loggedIn) {
        const current_path = window.location.pathname;
        if (
          current_path !== '/confirm' && 
          current_path !== '/forgot-password-confirm' &&
          current_path !== '/forgot-password' &&
          current_path !== '/payments/success' &&
          current_path !== '/register'
        ) {
          this.router.navigate(['login']);
        }
        this.username = '';
      } else {
        const claims = this._authService.getToken();
        const decoded: any = this.jwt_decode.parseJwt(claims);
        this.username = decodeURIComponent(escape(decoded.name));
      }
      this.menuService.loadMenu(loggedIn);
    });
    this.isLoggedIn = this._authService.isLoggedIn();
  }

  public doRefresh(event) {
    event.target.complete();
    // sessionStorage.removeItem('appMenu');
    // sessionStorage.removeItem('appMenu');
    this.menuService.setSelectedMenu({ title: ''});
    this._authService.refreshToken().subscribe(x => {
      location.reload();
    });
  }

  public login() {
    this.router.navigate(['login']);
  }

  public logout() {
    this._authService.signout();
    location.reload();
  }
}
