import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {

  constructor(public toastController: ToastController) {}

  async presentToast(message: string, color: string = 'dark', duration: number = 2000) {
    const toast = await this
            .toastController
            .create({'color': color, 'position': 'bottom', 'duration': duration, 'message': message});
    toast.present();
  }
}
