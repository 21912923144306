import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SeriesService } from './core/api/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SponsorsService implements OnDestroy {
  public sponsorsDataModel = new BehaviorSubject<any[]>(null);
  public sponsorsService$: Observable<any[]> = this.sponsorsDataModel.asObservable();

  constructor(
    private _seriesService: SeriesService
  ) {}
  ngOnDestroy() {
    this.sponsorsDataModel.next(null);
    this.sponsorsDataModel.complete();
  }
  setData(sponsors: any[]): void {
    this.sponsorsDataModel.next(sponsors);
  }
  loadSponsors() {
    const params = {
      position: 'sponsor-header'
    }
    this._seriesService.getGtSponsors(params).subscribe(res => {
      const slides = [];
      if (res['sponsor.sponsor-gold.sponsor-header']) {
        res['sponsor.sponsor-gold.sponsor-header'].forEach(prod => {
          if (prod['image'] && prod['image']['public_urls']) {
            const img = environment.api.host + prod['image']['public_urls']['big']['url'];
            const slide = {
              headerImage: img,
              title: prod.description,
              subtitle: prod.short_description,
              link: prod.aditional_name
            }
            slides.push(slide);
          }
        });
      }
      this.setData(slides);
    });
  }
}
