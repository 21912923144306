import { Component, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { EventEntity, Organization } from 'src/app/services/core/api/models';
import { EventsService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventListComponent implements OnInit {

  isHeaderVisible = false;

  public selectedClub;
  public clubs: Organization[] = [];
  public events: EventEntity[] = [];

  constructor(
    private eventsService: EventsService,
    public actionSheetCtrl: ActionSheetController,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.loadClubs();
  }

  loadClubs() {
    this.eventsService.getPccClubList().subscribe(clubs => {
      this.clubs = clubs.clubs;
    });
  }

  selectClub() {
    this.loadEvents();
  }

  loadEvents() {
    const params: any = {
      club: this.selectedClub
    };
    this.eventsService.getPccEventList(params).subscribe(events => {
      this.events = events.events;
    });
  }

  dividerFunction(key) {
    return key;
  }

  async presentActionSheet(item) {
    if (event) {
      event.stopPropagation();
    }
    const acSheet: any = {
      "buttons": [
          {
              "text": "Mark as read",
              "role": "destructive"
          },
          {
              "text": "Follow author"
          },
          {
              "text": "Read more info"
          },
          {
              "text": "More from category"
          },
          {
              "text": "Cancel",
              "role": "cancel"
          }
      ]
    };
    const context = this;
    const actionSheet = await this.actionSheetCtrl.create(acSheet);
      acSheet.buttons.forEach(element => {
        element['handler'] = function () {
          context.onItemClickActionSheet({ 'button': element, 'item': item });
        };
    });
    await actionSheet.present();
  }

  onItemClickActionSheet(params): void {
    console.dir(params);
    this.toastCtrl.presentToast('onItemClickActionSheet');
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }

}
