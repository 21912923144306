import { Component, OnInit } from '@angular/core';
import localeDe from '@angular/common/locales/de-CH';

import { registerLocaleData } from '@angular/common';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { SeriesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { environment } from 'src/environments/environment';
import { TokenHelperService } from 'src/app/shared/auth/token-helper-service.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'app-monthly-prices',
  templateUrl: './monthly-prices.component.html',
  styleUrls: ['./monthly-prices.component.scss'],
})
export class MonthlyPricesComponent implements OnInit {

  data = {
    products: []
  };
  selectedItem = null;
  isHeaderVisible = false;
  introtext = 'page.monthly.introtext';

  constructor(
    private _authService: AuthService,
    private _seriesService: SeriesService,
    private jwt_decode: TokenHelperService,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.setupProducts();
  }

  setupProducts() {

    const claims = this._authService.getToken();
    const decoded: any = this.jwt_decode.parseJwt(claims);
    const mom = moment().format("MMYYYY");

    let canSelectPrice = false;
    if (decoded.Monthly) {
      decoded.Monthly.forEach(element => {
        if (element === mom) {
          canSelectPrice = true;
          this.introtext = 'page.monthly.introtext.won';
        }
      });
    }
    if (!this._authService.containRole('ROLE_SERIES')) {
      canSelectPrice = false;
      this.introtext = 'page.monthly.introtext';
    } else {
      if (!canSelectPrice) {
        this.introtext = 'page.monthly.introtext.notwon'
      }
    }
    
    this.data.products = [];
    const params: any = {};
    this._seriesService.getGtPrices(params).subscribe(prods => {
      prods.products.forEach(prod => {
        let image_url = 'assets/imgs/background/21.jpg';
        if(prod['image']) {
          image_url = environment.api.host + prod['image']['public_urls']['big']['url'];
        }
        const item = {
          "id": prod.id,
          "image": image_url,
          "title": prod.name,
          "subtitle": prod.aditional_name,
          "button": 'auswählen',
          "canselect": prod.stock > 0,
          "isAllowed": canSelectPrice
        };
        this.data.products.push(item);
      });
    });
  }
  
  doRefresh(event) {
    event.target.complete();
    this.setupProducts();
  }

  onItemClickFunc(item: any) {
    if (event) {
      event.stopPropagation();
    }
    this.preselect(item);
  }

  preselect(item) {
    if (this.selectedItem === item) {
      this.selectedItem = null;
    } else if (item.canselect === true) {
      this.selectedItem = item;
    }
  }

  choose(item) {
    const params = {
      product: item.id
    };
    this._seriesService.selectMonthlyPrice(params).subscribe(res => {
      let type = 'success';
      let msg = 'page.monthly.loading.success';
      if (res.success === false) {
        type = 'danger';
        msg = 'page.monthly.loading.error';
      }
      this.setupProducts();
      this.translationService.get(msg).subscribe(
        value => {
          // value is our translated string
          this.toastCtrl.presentToast(value, type);
        }
      );
    });
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }

}
