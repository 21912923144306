import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Customer, Organization } from 'src/app/services/core/api/models';
import { EspVenue } from 'src/app/services/core/api/models/esp-venue';
import { SeriesService, TeetimesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
 
registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'gt-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {

  private customer: Customer;

  private isSubjectValid = true;
  private isMessageValid = true;
  
  public isDisabled = true;
  public success = true;
  public didSave = false;
  public isLoading = false;

  data: any = {
    "background": "assets/imgs/background/Login_wide.jpg",
  };

  item = {
    'subject': '',
    'message': ''
  };

  constructor(
    private seriesService: SeriesService,
    private loadingService: LoadingSpinnerService,
    private authService: AuthService,
    private toastCtrl: ToastService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    this.loadingService.isLoading.subscribe(loading => {
      this.isLoading = loading;
    });
  }

  onRegisterFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    this.didSave = false;
    if (this.validate()) {
      
      const params = {
        body: {
          subject: this.item.subject,
          message: this.item.message
        }
      }
      this.seriesService.doSendContactPost(params).subscribe(s => {
        this.success = s.error && s.error === 'SUCCESS';
        if (!this.success) {
          this.translationService.get(s.error_description).subscribe(
            value => {
              this.toastCtrl.presentToast(value, 'danger');
            }
          );
        } else {
          this.translationService.get('page.contact.form.send.success').subscribe(
            value => {
              this.toastCtrl.presentToast(value, 'success');
            }
          );
          this.didSave = true;
        }
      });
    }
  }

  validate(): boolean {
    this.isSubjectValid = true;
    this.isMessageValid = true;

    if (!this.item.subject || this.item.subject.length === 0) {
      this.isSubjectValid = false;
    }

    if (!this.item.message || this.item.message.length === 0) {
      this.isMessageValid = false;
    }

    return this.isSubjectValid &&
      this.isMessageValid;
  }

  scrolling(event) {
    setTimeout(()=> {
      
    });
  }

}
