import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from 'src/app/services/toast-service';

@Component({
  selector: 'app-stripe-error',
  templateUrl: './stripe-error.component.html',
  styleUrls: ['./stripe-error.component.scss'],
})
export class StripeErrorComponent implements OnInit {

  constructor(
    private router: Router,
    private toastCtrl: ToastService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    const msg = 'page.payment.stripe.error';
    this.translationService.get(msg).subscribe(
      value => {
        // value is our translated string
        this.toastCtrl.presentToast(value);
        this.router.navigate(['home']);
      }
    );
  }
}
