import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../services/core/api/models';

@Injectable({
  providedIn: 'root'
})
export class ZeileSelectionService {

    private bookingPersons = new BehaviorSubject<Customer[]>([]);
    public bookingPersons$: Observable<Customer[]> = this.bookingPersons.asObservable();

    private teetimeZeile = new BehaviorSubject<any>(null);
    public teetimeZeile$: Observable<any> = this.teetimeZeile.asObservable();

    private teetimes = new BehaviorSubject<any>(null);
    public teetimes$: Observable<any> = this.teetimes.asObservable();

    private selectedDate = new BehaviorSubject<string>(null);
    public selectedDate$: Observable<string> = this.selectedDate.asObservable();

    constructor() {
    }

    public setSelectedDate(selectedDate: string) {
        if (selectedDate && selectedDate !== null && selectedDate !== undefined) {
            localStorage.setItem('selectedDate', JSON.stringify(selectedDate));
            this.selectedDate.next(selectedDate);
        }
    }

    public getSelectedDate() {
        let selectedDate: string = null;
        const selectedDateStr = localStorage.getItem('selectedDate');
        if (selectedDateStr) {
            selectedDate = JSON.parse(selectedDateStr);
        }
        return selectedDate;
    }

    public setTeetimeZeile(teetimeZeile: any) {
        if (teetimeZeile && teetimeZeile !== null && teetimeZeile !== undefined) {
            localStorage.setItem('teetimeZeile', JSON.stringify(teetimeZeile));
            this.teetimeZeile.next(teetimeZeile);
        }
    }

    public getTeetimeZeile() {
        let teetimeZeile: any = null;
        const teetimeZeileStr = localStorage.getItem('teetimeZeile');
        if (teetimeZeileStr) {
            teetimeZeile = JSON.parse(teetimeZeileStr);
        }
        return teetimeZeile;
    }

    public setTeetimes(teetimes: any) {
        if (teetimes && teetimes !== null && teetimes !== undefined) {
            localStorage.setItem('teetimes', JSON.stringify(teetimes));
            this.teetimes.next(teetimes);
        }
    }

    public getTeetimes() {
        let teetimes: any = null;
        const teetimesStr = localStorage.getItem('teetimes');
        if (teetimesStr) {
            teetimes = JSON.parse(teetimesStr);
        }
        return teetimes;
    }

    public setBookingPersons(persons: Customer[]) {
        if (persons && persons !== null && persons !== undefined) {
            localStorage.setItem('bookingPersons', JSON.stringify(persons));
            this.bookingPersons.next(persons);
        }
    }

    public getBookingPersons() {
        let persons: Customer[] = [];
        const personsStr = localStorage.getItem('bookingPersons');
        if (personsStr) {
            persons = JSON.parse(personsStr);
        }
        return persons;
    }

    public canDelete(player: any, customer: Customer) {
        let can = false;
        if (customer) {
          if (customer.customer_id && player && player.persid && player.persid.length >= 13 && customer.customer_id === player.persid) {
            const clubid = player.persid.substr(0, 7);
            const playerid = player.persid.substr(7, 6);
            if (clubid != '0000000' && playerid != '000000') {
              can = true;
            }
          }
    
          // check bookings done
          if (customer.pcc_bookings && player.description) {
            customer.pcc_bookings.forEach(element => {
              if (element.booking_id === player.description) {
                can = true;
              }
            });
          }
        }
        return can;
      }
}
