import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Customer, Organization } from 'src/app/services/core/api/models';
import { EspVenue } from 'src/app/services/core/api/models/esp-venue';
import { SeriesService, TeetimesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
 
registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'gt-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {

  private customer: Customer;

  private isEmailValid = true;
  private isAddress1Valid = true;
  private isAddress2Valid = true;
  private isAddress3Valid = true;
  private isPostcodeValid = true;
  private isCityValid = true;
  private isCountryValid = true;
  
  public isDisabled = true;
  public success = true;
  public didSave = false;
  public isLoading = false;
  private regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  public countries = [];
  data: any = {
    "background": "assets/imgs/background/Login_wide.jpg",
  };

  item = {
    'address1': '',
    'address2': '',
    'address3': '',
    'postcode': '',
    'city': '',
    'country': '',
    'email': '',
    'tel': ''
  };

  constructor(
    private seriesService: SeriesService,
    private loadingService: LoadingSpinnerService,
    private authService: AuthService,
    private toastCtrl: ToastService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.authService.customer$.subscribe(customer => {
      this.customer = customer;
      this.setCustomerInfos();
    });
    this.customer = this.authService.getCustomer();

    this.loadingService.isLoading.subscribe(loading => {
      this.isLoading = loading;
    });
    this.setCustomerInfos();
  }

  setCustomerInfos() {
    this.setCountries();
    if (this.customer.addresses && this.customer.addresses.length > 0) {
      const address = this.customer.addresses[0];
      this.item.email = address.email;
      this.item.tel = address.phone;
      this.item.address1 = address.address1;
      this.item.address2 = address.address2;
      this.item.address3 = address.address3;
      this.item.postcode = address.postcode;
      this.item.city = address.city;
      this.item.country = address.country_code;
    } else {
      this.item.email = this.customer.email;
    }
  }

  onRegisterFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    this.didSave = false;
    if (this.validate()) {
      
      const params = {
        body: {
          email: this.item.email,
          tel: this.item.tel,
          address1: this.item.address1,
          address2: this.item.address2,
          address3: this.item.address3,
          postcode: this.item.postcode,
          city: this.item.city,
          country: this.item.country,
        }
      }
      this.seriesService.updateProfileAddress(params).subscribe(s => {
        this.didSave = true;
        this.success = s.success;
        if (!this.success) {
          this.toastCtrl.presentToast(s.error_description, 'danger');
        } else {
          this.authService.setCustomer(s.customer);
          this.dismiss();
        }
      });
    }
  }

  validate(): boolean {
    this.isEmailValid = true;
    this.isAddress1Valid = true;
    this.isAddress2Valid = true;
    this.isAddress3Valid = true;
    this.isPostcodeValid = true;
    this.isCityValid = true;
    this.isCountryValid = true;

    if (!this.item.address1 || this.item.address1.length === 0) {
      this.isAddress1Valid = false;
    }

    if (!this.item.postcode || this.item.postcode.length === 0) {
      this.isPostcodeValid = false;
    }

    if (!this.item.city || this.item.city.length === 0) {
      this.isCityValid = false;
    }

    if (!this.item.country || this.item.country.length === 0) {
      this.isCountryValid = false;
    }

    // this.isEmailValid = this.regex.test(this.item.email);

    return this.isAddress1Valid &&
      this.isPostcodeValid &&
      this.isCityValid &&
      this.isCountryValid;
  }

  save() {
    this.dismiss();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'customer': this.customer
    });
  }

  setCountries() {
    const shortcuts = [
      { "text": "Switzerland", "value": "CH" },
      { "text": "Germany", "value": "DE" },
      { "text": "Austria", "value": "AT" },
      { "text": "Italy", "value": "IT" },
      { "text": "France", "value": "FR" },
    ]
    const countries = [
      { "text": "Afghanistan", "value": "AF" },
      { "text": "Åland Islands", "value": "AX" },
      { "text": "Albania", "value": "AL" },
      { "text": "Algeria", "value": "DZ" },
      { "text": "American Samoa", "value": "AS" },
      { "text": "Andorra", "value": "AD" },
      { "text": "Angola", "value": "AO" },
      { "text": "Anguilla", "value": "AI" },
      { "text": "Antarctica", "value": "AQ" },
      { "text": "Antigua and Barbuda", "value": "AG" },
      { "text": "Argentina", "value": "AR" },
      { "text": "Armenia", "value": "AM" },
      { "text": "Aruba", "value": "AW" },
      { "text": "Australia", "value": "AU" },
      { "text": "Azerbaijan", "value": "AZ" },
      { "text": "Bahamas", "value": "BS" },
      { "text": "Bahrain", "value": "BH" },
      { "text": "Bangladesh", "value": "BD" },
      { "text": "Barbados", "value": "BB" },
      { "text": "Belarus", "value": "BY" },
      { "text": "Belgium", "value": "BE" },
      { "text": "Belize", "value": "BZ" },
      { "text": "Benin", "value": "BJ" },
      { "text": "Bermuda", "value": "BM" },
      { "text": "Bhutan", "value": "BT" },
      { "text": "Bolivia", "value": "BO" },
      { "text": "Bosnia and Herzegovina", "value": "BA" },
      { "text": "Botswana", "value": "BW" },
      { "text": "Bouvet Island", "value": "BV" },
      { "text": "Brazil", "value": "BR" },
      { "text": "British Indian Ocean Territory", "value": "IO" },
      { "text": "Brunei Darussalam", "value": "BN" },
      { "text": "Bulgaria", "value": "BG" },
      { "text": "Burkina Faso", "value": "BF" },
      { "text": "Burundi", "value": "BI" },
      { "text": "Cambodia", "value": "KH" },
      { "text": "Cameroon", "value": "CM" },
      { "text": "Canada", "value": "CA" },
      { "text": "Cape Verde", "value": "CV" },
      { "text": "Cayman Islands", "value": "KY" },
      { "text": "Central African Republic", "value": "CF" },
      { "text": "Chad", "value": "TD" },
      { "text": "Chile", "value": "CL" },
      { "text": "China", "value": "CN" },
      { "text": "Christmas Island", "value": "CX" },
      { "text": "Cocos (Keeling) Islands", "value": "CC" },
      { "text": "Colombia", "value": "CO" },
      { "text": "Comoros", "value": "KM" },
      { "text": "Congo", "value": "CG" },
      { "text": "Congo, The Democratic Republic of the", "value": "CD" },
      { "text": "Cook Islands", "value": "CK" },
      { "text": "Costa Rica", "value": "CR" },
      { "text": "Cote D'Ivoire", "value": "CI" },
      { "text": "Croatia", "value": "HR" },
      { "text": "Cuba", "value": "CU" },
      { "text": "Cyprus", "value": "CY" },
      { "text": "Czech Republic", "value": "CZ" },
      { "text": "Denmark", "value": "DK" },
      { "text": "Djibouti", "value": "DJ" },
      { "text": "Dominica", "value": "DM" },
      { "text": "Dominican Republic", "value": "DO" },
      { "text": "Ecuador", "value": "EC" },
      { "text": "Egypt", "value": "EG" },
      { "text": "El Salvador", "value": "SV" },
      { "text": "Equatorial Guinea", "value": "GQ" },
      { "text": "Eritrea", "value": "ER" },
      { "text": "Estonia", "value": "EE" },
      { "text": "Ethiopia", "value": "ET" },
      { "text": "Falkland Islands (Malvinas)", "value": "FK" },
      { "text": "Faroe Islands", "value": "FO" },
      { "text": "Fiji", "value": "FJ" },
      { "text": "Finland", "value": "FI" },
      { "text": "French Guiana", "value": "GF" },
      { "text": "French Polynesia", "value": "PF" },
      { "text": "French Southern Territories", "value": "TF" },
      { "text": "Gabon", "value": "GA" },
      { "text": "Gambia", "value": "GM" },
      { "text": "Georgia", "value": "GE" },
      { "text": "Ghana", "value": "GH" },
      { "text": "Gibraltar", "value": "GI" },
      { "text": "Greece", "value": "GR" },
      { "text": "Greenland", "value": "GL" },
      { "text": "Grenada", "value": "GD" },
      { "text": "Guadeloupe", "value": "GP" },
      { "text": "Guam", "value": "GU" },
      { "text": "Guatemala", "value": "GT" },
      { "text": "Guernsey", "value": "GG" },
      { "text": "Guinea", "value": "GN" },
      { "text": "Guinea-Bissau", "value": "GW" },
      { "text": "Guyana", "value": "GY" },
      { "text": "Haiti", "value": "HT" },
      { "text": "Heard Island and Mcdonald Islands", "value": "HM" },
      { "text": "Holy See (Vatican City State)", "value": "VA" },
      { "text": "Honduras", "value": "HN" },
      { "text": "Hong Kong", "value": "HK" },
      { "text": "Hungary", "value": "HU" },
      { "text": "Iceland", "value": "IS" },
      { "text": "India", "value": "IN" },
      { "text": "Indonesia", "value": "ID" },
      { "text": "Iran, Islamic Republic Of", "value": "IR" },
      { "text": "Iraq", "value": "IQ" },
      { "text": "Ireland", "value": "IE" },
      { "text": "Isle of Man", "value": "IM" },
      { "text": "Israel", "value": "IL" },
      { "text": "Jamaica", "value": "JM" },
      { "text": "Japan", "value": "JP" },
      { "text": "Jersey", "value": "JE" },
      { "text": "Jordan", "value": "JO" },
      { "text": "Kazakhstan", "value": "KZ" },
      { "text": "Kenya", "value": "KE" },
      { "text": "Kiribati", "value": "KI" },
      { "text": "Korea, Democratic People'S Republic of", "value": "KP" },
      { "text": "Korea, Republic of", "value": "KR" },
      { "text": "Kuwait", "value": "KW" },
      { "text": "Kyrgyzstan", "value": "KG" },
      { "text": "Lao People'S Democratic Republic", "value": "LA" },
      { "text": "Latvia", "value": "LV" },
      { "text": "Lebanon", "value": "LB" },
      { "text": "Lesotho", "value": "LS" },
      { "text": "Liberia", "value": "LR" },
      { "text": "Libyan Arab Jamahiriya", "value": "LY" },
      { "text": "Liechtenstein", "value": "LI" },
      { "text": "Lithuania", "value": "LT" },
      { "text": "Luxembourg", "value": "LU" },
      { "text": "Macao", "value": "MO" },
      { "text": "Macedonia, The Former Yugoslav Republic of", "value": "MK" },
      { "text": "Madagascar", "value": "MG" },
      { "text": "Malawi", "value": "MW" },
      { "text": "Malaysia", "value": "MY" },
      { "text": "Maldives", "value": "MV" },
      { "text": "Mali", "value": "ML" },
      { "text": "Malta", "value": "MT" },
      { "text": "Marshall Islands", "value": "MH" },
      { "text": "Martinique", "value": "MQ" },
      { "text": "Mauritania", "value": "MR" },
      { "text": "Mauritius", "value": "MU" },
      { "text": "Mayotte", "value": "YT" },
      { "text": "Mexico", "value": "MX" },
      { "text": "Micronesia, Federated States of", "value": "FM" },
      { "text": "Moldova, Republic of", "value": "MD" },
      { "text": "Monaco", "value": "MC" },
      { "text": "Mongolia", "value": "MN" },
      { "text": "Montserrat", "value": "MS" },
      { "text": "Morocco", "value": "MA" },
      { "text": "Mozambique", "value": "MZ" },
      { "text": "Myanmar", "value": "MM" },
      { "text": "Namibia", "value": "NA" },
      { "text": "Nauru", "value": "NR" },
      { "text": "Nepal", "value": "NP" },
      { "text": "Netherlands", "value": "NL" },
      { "text": "Netherlands Antilles", "value": "AN" },
      { "text": "New Caledonia", "value": "NC" },
      { "text": "New Zealand", "value": "NZ" },
      { "text": "Nicaragua", "value": "NI" },
      { "text": "Niger", "value": "NE" },
      { "text": "Nigeria", "value": "NG" },
      { "text": "Niue", "value": "NU" },
      { "text": "Norfolk Island", "value": "NF" },
      { "text": "Northern Mariana Islands", "value": "MP" },
      { "text": "Norway", "value": "NO" },
      { "text": "Oman", "value": "OM" },
      { "text": "Pakistan", "value": "PK" },
      { "text": "Palau", "value": "PW" },
      { "text": "Palestinian Territory, Occupied", "value": "PS" },
      { "text": "Panama", "value": "PA" },
      { "text": "Papua New Guinea", "value": "PG" },
      { "text": "Paraguay", "value": "PY" },
      { "text": "Peru", "value": "PE" },
      { "text": "Philippines", "value": "PH" },
      { "text": "Pitcairn", "value": "PN" },
      { "text": "Poland", "value": "PL" },
      { "text": "Portugal", "value": "PT" },
      { "text": "Puerto Rico", "value": "PR" },
      { "text": "Qatar", "value": "QA" },
      { "text": "Reunion", "value": "RE" },
      { "text": "Romania", "value": "RO" },
      { "text": "Russian Federation", "value": "RU" },
      { "text": "RWANDA", "value": "RW" },
      { "text": "Saint Helena", "value": "SH" },
      { "text": "Saint Kitts and Nevis", "value": "KN" },
      { "text": "Saint Lucia", "value": "LC" },
      { "text": "Saint Pierre and Miquelon", "value": "PM" },
      { "text": "Saint Vincent and the Grenadines", "value": "VC" },
      { "text": "Samoa", "value": "WS" },
      { "text": "San Marino", "value": "SM" },
      { "text": "Sao Tome and Principe", "value": "ST" },
      { "text": "Saudi Arabia", "value": "SA" },
      { "text": "Senegal", "value": "SN" },
      { "text": "Serbia and Montenegro", "value": "CS" },
      { "text": "Seychelles", "value": "SC" },
      { "text": "Sierra Leone", "value": "SL" },
      { "text": "Singapore", "value": "SG" },
      { "text": "Slovakia", "value": "SK" },
      { "text": "Slovenia", "value": "SI" },
      { "text": "Solomon Islands", "value": "SB" },
      { "text": "Somalia", "value": "SO" },
      { "text": "South Africa", "value": "ZA" },
      { "text": "South Georgia and the South Sandwich Islands", "value": "GS" },
      { "text": "Spain", "value": "ES" },
      { "text": "Sri Lanka", "value": "LK" },
      { "text": "Sudan", "value": "SD" },
      { "text": "Suriname", "value": "SR" },
      { "text": "Svalbard and Jan Mayen", "value": "SJ" },
      { "text": "Swaziland", "value": "SZ" },
      { "text": "Sweden", "value": "SE" },
      { "text": "Syrian Arab Republic", "value": "SY" },
      { "text": "Taiwan, Province of China", "value": "TW" },
      { "text": "Tajikistan", "value": "TJ" },
      { "text": "Tanzania, United Republic of", "value": "TZ" },
      { "text": "Thailand", "value": "TH" },
      { "text": "Timor-Leste", "value": "TL" },
      { "text": "Togo", "value": "TG" },
      { "text": "Tokelau", "value": "TK" },
      { "text": "Tonga", "value": "TO" },
      { "text": "Trinidad and Tobago", "value": "TT" },
      { "text": "Tunisia", "value": "TN" },
      { "text": "Turkey", "value": "TR" },
      { "text": "Turkmenistan", "value": "TM" },
      { "text": "Turks and Caicos Islands", "value": "TC" },
      { "text": "Tuvalu", "value": "TV" },
      { "text": "Uganda", "value": "UG" },
      { "text": "Ukraine", "value": "UA" },
      { "text": "United Arab Emirates", "value": "AE" },
      { "text": "United Kingdom", "value": "GB" },
      { "text": "United States", "value": "US" },
      { "text": "United States Minor Outlying Islands", "value": "UM" },
      { "text": "Uruguay", "value": "UY" },
      { "text": "Uzbekistan", "value": "UZ" },
      { "text": "Vanuatu", "value": "VU" },
      { "text": "Venezuela", "value": "VE" },
      { "text": "Viet Nam", "value": "VN" },
      { "text": "Virgin Islands, British", "value": "VG" },
      { "text": "Virgin Islands, U.S.", "value": "VI" },
      { "text": "Wallis and Futuna", "value": "WF" },
      { "text": "Western Sahara", "value": "EH" },
      { "text": "Yemen", "value": "YE" },
      { "text": "Zambia", "value": "ZM" },
      { "text": "Zimbabwe", "value": "ZW" }
    ];
    countries.sort((a,b) => a.text.localeCompare(b.text));

    this.countries.push({
      value: '',
      text: 'Unbekannt'
    });
    shortcuts.forEach(element => {
      this.countries.push(element);
    });
    countries.forEach(element => {
      this.countries.push(element);
    });
  }

  scrolling(event) {
    setTimeout(()=> {
      
    });
  }

}
