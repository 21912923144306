import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared';

import * as fromPages from './pages';

const routes: Routes = [
  { path: 'events/list', component: fromPages.EventListComponent, canActivate: [AuthGuard] },
  { path: 'contact', component: fromPages.ContactFormComponent, canActivate: [AuthGuard] },
  { path: 'sponsors', component: fromPages.SponsorsComponent, canActivate: [AuthGuard] },
  { path: 'teetimes', component: fromPages.TeetimesComponent, canActivate: [AuthGuard] },
  { path: 'teetimes/zeile/:date/:time', component: fromPages.ZeileComponent, canActivate: [AuthGuard] },
  { path: 'shop', component: fromPages.MonthlyPricesComponent, canActivate: [AuthGuard] },
  { path: 'payments', component: fromPages.StripeJavaScriptComponent, canActivate: [AuthGuard] },
  { path: 'payments/success', component: fromPages.StripeSuccessComponent },
  { path: 'payments/cancel', component: fromPages.StripeErrorComponent, canActivate: [AuthGuard] },
  { path: 'challenges', loadChildren: () => import('./pages/challenges/challenges-module/challenges.module').then(m => m.ChallengesPageModule), canActivate: [AuthGuard] },
  { path: 'series', loadChildren: () => import('./pages/series/series-module/series.module').then(m => m.SeriesPageModule), canActivate: [AuthGuard] },
  { path: 'profile', component: fromPages.ProfileOverviewComponent, canActivate: [AuthGuard] },
  { path: 'home', component: fromPages.HomeComponent, canActivate: [AuthGuard] },
  { path: 'login',  component: fromPages.LoginLayout2Page },
  { path: 'register',  component: fromPages.RegisterLayout1Page },
  { path: 'confirm',  component: fromPages.RegisterConfirmPage },
  { path: 'forgot-password',  component: fromPages.ForgotPasswordPage },
  { path: 'forgot-password-confirm',  component: fromPages.ForgotPasswordConfirmPage },
  { path: 'auth-callback',  component: fromPages.AuthCallbackPageComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'corrected' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
