import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Customer, Organization } from 'src/app/services/core/api/models';
import { EspVenue } from 'src/app/services/core/api/models/esp-venue';
import { TeetimesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService, ZeileSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { HomeComponent } from '../../home/home.component';
import { ZeileComponent } from '../zeile/zeile.component';
 
registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'app-teetimes',
  templateUrl: './teetimes.component.html',
  styleUrls: ['./teetimes.component.scss'],
})
export class TeetimesComponent implements OnInit {

  isHeaderVisible = false;
  public deCHpipe: DatePipe;

  public timetable = [];
  public clubs: Organization[] = [];
  public venues: EspVenue[] = [];
  public clubVenues: EspVenue[] = [];

  public minDate = '';
  public maxDate = '';
  public selectedDate: string;

  public selectedVenue = 0;
  public selectedVenueObj: EspVenue;
  public selectedClub = 0;
  public selectedClubObj: Organization;

  public didNotScroll = true;
  public scrollTimestamp = null;
  get current_time() {
    return moment().unix();
  }

  private customer: Customer;

  constructor(
    private teetimeService: TeetimesService,
    private loadingService: LoadingSpinnerService,
    private clubSelectionService: ClubSelectionService,
    private alertController: AlertController,
    private authService: AuthService,
    private toastCtrl: ToastService,
    private router: Router,
    private translationService: TranslateService,
    private zeileSelectionService: ZeileSelectionService
  ) { }

  ngOnInit() {
    this.authService.customer$.subscribe(customer => {
      this.customer = customer;
    });
    this.customer = this.authService.getCustomer();

    this.clubSelectionService.club$.subscribe(selectedClub => {
      if (selectedClub) {
        this.selectedClubObj = selectedClub;
        this.selectedClub = selectedClub.id;
      }
    });

    this.clubSelectionService.venue$.subscribe(selectedVenue => {
      this.selectedVenueObj = selectedVenue;
      this.selectedVenue = selectedVenue ? selectedVenue.id : 0;
    });
    this.initDatePicker();
    this.loadAreas();
  }

  ionViewWillEnter() {
    this.zeileSelectionService.teetimeZeile$.subscribe(teetimeZeile => {
      if (teetimeZeile) {
        this.scrollTimestamp = teetimeZeile.zeile.timestamp;
        this.scrollTo();
      } else {
        this.scrollTimestamp = null;
      }
    });
    this.zeileSelectionService.teetimes$.subscribe(teetimes => {
      if (teetimes) {
        this.displayTeetimes(teetimes)
      }
    });
  }

  initDatePicker() {
    // this.deCHpipe = new DatePipe('deCH'); // Use your own locale
    // const dateNow = this.deCHpipe.transform(new Date(), 'yyyy-MM-dd');
    this.selectedDate = this.zeileSelectionService.getSelectedDate();
    const today = moment(new Date()).format('YYYY-MM-DD');
    if (!this.selectedDate || this.selectedDate < today) {
      this.selectedDate = today;
      this.zeileSelectionService.setSelectedDate(this.selectedDate);
    }

    this.minDate = moment(new Date()).format('YYYY-MM-DD');
    this.maxDate = moment(new Date()).add(90, 'd').format('YYYY-MM-DD');
  }

  addEvent() {
    this.zeileSelectionService.setSelectedDate(this.selectedDate);
  }

  selectClub() {
    this.selectedClubObj = this.clubs.find(x => x.id === this.selectedClub);
    this.clubVenues = this.venues.filter(x => x.organization.id === this.selectedClub);

    this.clubVenues.sort((a,b) => a.name.localeCompare(b.name));

    this.clubSelectionService.setClub(this.selectedClubObj);
    this.clubSelectionService.removeVenue();
  }

  selectVenue() {
    this.selectedVenueObj = this.venues.find(x => x.id === this.selectedVenue);
    this.clubSelectionService.setVenue(this.selectedVenueObj);
    // this.loadTeetimes();
  }

  loadAreas() {
    this.teetimeService.getPccVenueList().subscribe(venues => {
      venues.venues.forEach(venue => {
        if (!this.clubs.find(x => x.id == venue.organization.id)) {
          this.clubs.push(venue.organization);
        }
      });
      this.clubs.sort((a,b) => a.name.localeCompare(b.name));
      this.venues = venues.venues;

      this.selectedClubObj = this.clubSelectionService.getClub();
      if (this.selectedClubObj) {
        this.selectedClub = this.selectedClubObj.id;
        this.clubVenues = this.venues.filter(x => x.organization.id === this.selectedClub);
        this.clubVenues.sort((a,b) => a.name.localeCompare(b.name));
      }
      this.selectedVenueObj = this.clubSelectionService.getVenue();
      if (this.selectedVenueObj) {
        this.selectedVenue = this.selectedVenueObj.id;
        this.loadTeetimes();
      }
    });
  }

  doRefresh(event) {
    event.target.complete();
    this.loadTeetimes();
  }

  loadTeetimes() {
    // do not load Teetimes if no area or date is selected
    if (!this.selectedDate || !this.selectedVenue) {
      return;
    }
    this.timetable = [];

    // setTimeout(() => {
    //   this.scrollTo('content-top');
    // }, 100);

    const params = {
      date: this.selectedDate,
      venue: '' + this.selectedVenue
    }
    this.teetimeService.getPccTeetimeList(params).subscribe(data  => {
      this.zeileSelectionService.setTeetimes(data);
    });
  }

  displayTeetimes(data) {
    const latest_date = this.selectedDate;

    // check if Timetable send more times as possible book
    let time_max = data.venue.time_to;
    if (data.teetimes && data.teetimes.length > 0) {
      data.teetimes.sort((a,b) => a.teetime.localeCompare(b.teetime));
      const element = data.teetimes[data.teetimes.length-1];
      time_max = element.teetime;
    } else {
      return;
    }

    const localTimetable = [];

    // moment.locale('deCH');
    const dString1 = '' + latest_date + 'T' + data.venue.time_from + ':00+01:00';
    const dString2 = '' + latest_date + 'T' + time_max + ':00+01:00';
    const time_to = moment(dString2).parseZone();

    // const time_from = new Date(latest_date + 'T' + data.venue.time_from + ':00');
    // const time_to = new Date(latest_date + 'T' + time_max + ':00');
    const time_now = moment();
    let time_new = moment(dString1).parseZone();
    
    if (!this.scrollTimestamp) {
      this.scrollTimestamp = moment().unix();
    }

    const slots_book = data.venue.slots_book;
    const slots_view = data.venue.slots_view;
    const time_span = +data.venue.time_span;

    let scroll_timestamp_local = time_new.unix();
    while (time_new <= time_to) {
      const players = this.getTimePlayers(time_new, data, false);
      const pLength =  players.length;
      for(let index = slots_book; index >pLength; index--) {
        const empty = {
          first_name: '',
          last_name: '',
          bookable: true
        }
        players.push(empty);
      }
      const time = {
        time: time_new.format('HH:mm'),
        timestamp: time_new.unix(),
        players: players
      }
      if (this.scrollTimestamp > time.timestamp) {
        scroll_timestamp_local = time_new.unix();
      }

      localTimetable.push(time);
      time_new = time_new.add(time_span, 'm');
    }
    this.scrollTimestamp = scroll_timestamp_local;
    this.timetable = localTimetable;

    setTimeout(() => {
      this.scrollTo();
    }, 800);
  }

  scrollTo(scrollId = null) {
    let id = 'teetime-' + this.scrollTimestamp;
    if (scrollId !== null) {
      id = scrollId;
    }
    console.dir(id);
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      this.didNotScroll = false;
    }
  }

  getTimePlayers(time_new, data, debug: boolean) {
    let players = [];
    const slots_book = data.venue.slots_book;
    data.teetimes.forEach(element => {
      // console.dir(element);
      const dString1 = '' + element.pcc_event_draw_rounds.name + 'T' + element.teetime + ':00+01:00';
      const mP = moment(dString1).parseZone();
      
      // console.dir(mT);
      // console.dir(mP);
      if (mP.format() === time_new.format()) {
        if (element.player.length > 0 && element.player[0].name_to_show == 'BLOCK') {
          for (let index = 0; index < slots_book; index++) {
            const customer = {
              first_name: 'BLOCK',
              last_name: '',
              bookable: false
            }
            players.push(customer);
          }
        } else if (element.player.length === 0) {
          // no players yet
        } else {
          players = element.player;
        }
      }
    });

    return players;
  }

  async info(player, zeile) {
    const teetimeZeile = {
      'zeile': zeile,
      'selectedDate': this.selectedDate,
      'selectedVenueObj': this.selectedVenueObj,
      'selectedClubObj': this.selectedClubObj
    };
    this.zeileSelectionService.setTeetimeZeile(teetimeZeile);
    this.router.navigate(['/teetimes/zeile/'+this.selectedDate+'/'+zeile.time]);
  }

  canDelete(player: any) {
    return this.zeileSelectionService.canDelete(player, this.customer);
  }

  async delete(player, zeile) {
    if (zeile.timestamp >= this.current_time && this.canDelete(player)) {
      const buttons = [];
        const cancel = {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        };
        buttons.push(cancel);

        const accept = {
          text: 'Okay',
          handler: () => {
            // this.timetable = [];

            const params = {
              date: this.selectedDate,
              venue: '' + this.selectedVenue,
              booking: player.description,
              body: {
                load_teetimes: true
              }
            }
            this.teetimeService.deletePccTeetime(params).subscribe(data => {
              if (data['msg']) {
                let type = 'danger';
                if (data['msg'] === 'success') {
                  type = 'success';
                }
                this.toastCtrl.presentToast(data['msg'], type);
              }
              this.displayTeetimes(data);
            });
          }
        };
        buttons.push(accept);

        const msg = 'page.teetime.book.delete';
        this.translationService.get(msg).subscribe(
          async (value) => {
            const players = '' + player.first_name + ' ' + player.last_name;
            const alert = await this.alertController.create({
              header: value,
              message: '' + players,
              buttons: buttons
            });

            await alert.present();
          }
        );
    }
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }

}
