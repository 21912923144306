import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LoginService, SeriesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { AuthService } from 'src/app/shared/auth/auth.service';

@Component({
  selector: 'app-stripe-success',
  templateUrl: './stripe-success.component.html',
  styleUrls: ['./stripe-success.component.scss'],
})
export class StripeSuccessComponent implements OnInit {

  constructor(
    private _loginService: LoginService,
    private _authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastCtrl: ToastService,
    private translationService: TranslateService
  ) { }

  ngOnInit() {
    let session_id = '';
    this.route.queryParams.subscribe(params => {
      session_id = params['session_id'] ? params['session_id'] : null;
    });

    const params = {
      stripe_session: session_id,
      body: {
        refresh_token: this._authService.getRefreshToken()
      }
    };
    if (!params.stripe_session) {
      const msg = 'page.payment.stripe.error';
      this.translationService.get(msg).subscribe(
        value => {
          // value is our translated string
          this.toastCtrl.presentToast(value, 'danger');
          this.router.navigate(['home']);
        }
      );
      return;
    }
    this._loginService.dPublicUpdateStripeSessionPost(params).subscribe(response => {
      const msg = 'page.payment.stripe.success';
      this.translationService.get(msg).subscribe(
        value => {
          this.toastCtrl.presentToast(value, 'success');
          this._authService.SetToken(response);
          this.router.navigate(['home']);
        }
      );
    });
  }
}
