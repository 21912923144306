import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Customer, Organization } from 'src/app/services/core/api/models';
import { EspVenue } from 'src/app/services/core/api/models/esp-venue';
import { TeetimesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService } from 'src/app/shared';
import { ZeileSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { EditFriendComponent } from '../edit-friend/edit-friend.component';
 
registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'gt-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss'],
})
export class FriendsComponent implements OnInit {

  @Input()
  public selected: any[];

  @Input()
  public zeile;

  private customer: Customer;
  public friends: any = [];

  public maxBookings = 0;
  public golfclubs = [];
  public isDisabled = true;

  constructor(
    private teetimeService: TeetimesService,
    private loadingService: LoadingSpinnerService,
    private zeileSelectionService: ZeileSelectionService,
    private alertController: AlertController,
    private translationService: TranslateService,
    private authService: AuthService,
    private toastCtrl: ToastService,
    public modalController: ModalController,
    private clubSelectionService: ClubSelectionService
  ) { }

  ngOnInit() {
    this.authService.customer$.subscribe(customer => {
      this.customer = customer;
    });
    this.customer = this.authService.getCustomer();

    const selectedItem = this.selected.find(x => x.id === this.customer.id);
    this.friends.push({
      val: this.customer,
      isChecked: selectedItem ? true : false,
      disabled: false
    });

    this.customer.my_friends.forEach(element => {
      const selectedItem = this.selected.find(x => x.id === element.id);
      this.friends.push({
        val: element,
        isChecked: selectedItem ? true : false,
        disabled: false
      });
    });

    this.setGolfclubs();
    this.setMaxBookings();
  }

  checkboxChanged() {
    const item = this.friends.filter(x => x.isChecked === true);
    let disabled = false;
    if (item.length === 0) {
      disabled = true;
    }
    this.selected = this.friends.filter(x => x.isChecked === true);

    const selectedPersons = [];
    this.selected.forEach(element => {
      selectedPersons.push(element.val);
    });
    this.zeileSelectionService.setBookingPersons(selectedPersons);

    this.checkDisabled();

    this.isDisabled = disabled;
  }

  add() {
    this.dismiss();
  }

  toggleFriend(entry) {
    if (!entry.disabled) {
      const number = this.number(entry.val.customer_id);
      const itemCan = number && number !== '';
      if (itemCan) {
        entry.isChecked = !entry.isChecked;
      } else {
        this.translationService.get('page.teetime.friend.select.nonumber').subscribe(
          value => {
            // value is our translated string
            this.toastCtrl.presentToast(value, 'danger');
          }
        );
      }
    }
  }

  async editFriend(entry) {
    let friend = null;
    if (entry && entry.val) {
      friend = entry.val;
    }
    const modal = await this.modalController.create({
      component: EditFriendComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        friend: friend
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    if (data && data.friend) {
      if (entry && entry.val) {
        entry.val = data.friend;
      } else {
        entry = {
          isChecked: true,
          val: data.friend
        };
        this.friends.push(entry);
      }
    }
  }

  golfclub(customer_id) {
    const club = this.clubSelectionService.findClubByCustomerId(customer_id);
    if (!club) {
      return 'page.teetime.friend.noclub';
    }
    return club.name;
  }

  number(customer_id) {
    if (!customer_id || customer_id.length < 13) {
      return '';
    }
    return customer_id.substring(7);
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'selected': this.selected
    });
  }

  checkDisabled() {
    this.friends.forEach(element => {
      element.disabled = false;
    });
    if (this.maxBookings <= this.selected.length) {
      const friends = this.friends.filter(x => x.isChecked === false);
      friends.forEach(element => {
        element.disabled = true;
      });
    }
  }

  setMaxBookings() {
    this.zeile.players.forEach(element => {
      if (element.bookable) {
        this.maxBookings++;
      }
    });
  }

  setGolfclubs() {
    this.golfclubs = this.clubSelectionService.getClubs();
  }

  scrolling(event) {
    setTimeout(()=> {
      
    });
  }

}
