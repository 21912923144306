import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import * as fromPages from '../pages';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './auth/error.interceptor';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

import { IonicModule } from '@ionic/angular';
import { AnimateItemsDirective } from '../directives/animate-items.directive';
import { ParallaxHeader } from '../directives/parallax-header';
import { TokenHelperService } from './auth/token-helper-service.service';
import { UploadsDataService } from '../services/uploads-data.service';
import { SpinnerPage } from './spinner/spinner.page';
import { MenuService } from '../services/menu.service';
import { HeaderImagePage } from './header-image/header-image.page';
import { HeaderSponsorPage } from './header-sponsor/header-sponsor.page';

import { TranslateModule } from '@ngx-translate/core';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCardModule,
    MatButtonModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HeaderImagePage,
    HeaderSponsorPage,
    SpinnerPage,
    IonicModule,
    TranslateModule,
    SafeHtmlPipe
  ],
  declarations: [
    AnimateItemsDirective,
    ParallaxHeader,
    SpinnerPage,
    HeaderImagePage,
    HeaderSponsorPage,
    // RankComponent,
    fromPages.pages,
    SafeHtmlPipe
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    AuthService,
    DatePipe,
    InAppBrowser,
    TokenHelperService,
    UploadsDataService,
    MenuService
  ]
})
export class SharedModule { }
