/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Box } from '../models/box';
import { Customer } from '../models/customer';
import { EventAttendee2 } from '../models/event-attendee-2';
import { Prize } from '../models/prize';
import { StripeSession } from '../models/stripe-session';

@Injectable({
  providedIn: 'root',
})
export class SeriesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation selectMonthlyPrice
   */
  static readonly SelectMonthlyPricePath = '/api/golftime/monthlies/{product}/price/select';

  /**
   * select a monthly price.
   *
   * select a monthly price
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `selectMonthlyPrice()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectMonthlyPrice$Response(params: {
    product: string;
  }): Observable<StrictHttpResponse<{ 'success'?: boolean }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.SelectMonthlyPricePath, 'get');
    if (params) {
      rb.path('product', params.product, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'success'?: boolean }>;
      })
    );
  }

  /**
   * select a monthly price.
   *
   * select a monthly price
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `selectMonthlyPrice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  selectMonthlyPrice(params: {
    product: string;
  }): Observable<{ 'success'?: boolean }> {

    return this.selectMonthlyPrice$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'success'?: boolean }>) => r.body as { 'success'?: boolean })
    );
  }

  /**
   * Path part for operation doSendContactPost
   */
  static readonly DoSendContactPostPath = '/api/golftime/sends/contacts';

  /**
   * this call will send contact form.
   *
   * this call will contact form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doSendContactPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doSendContactPost$Response(params: {
    body: { 'subject': string, 'message': string }
  }): Observable<StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.DoSendContactPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * this call will send contact form.
   *
   * this call will contact form
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doSendContactPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doSendContactPost(params: {
    body: { 'subject': string, 'message': string }
  }): Observable<{ 'error'?: string, 'error_description'?: string }> {

    return this.doSendContactPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>) => r.body as { 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation sendImage
   */
  static readonly SendImagePath = '/api/golftime/images/{type}';

  /**
   * send an image of the scorecard.
   *
   * send an image of the scorecard
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendImage$Response(params: {
    type: string;
    body: { 'scorecard': Blob }
  }): Observable<StrictHttpResponse<{ 'success'?: boolean }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.SendImagePath, 'post');
    if (params) {
      rb.path('type', params.type, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'success'?: boolean }>;
      })
    );
  }

  /**
   * send an image of the scorecard.
   *
   * send an image of the scorecard
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendImage(params: {
    type: string;
    body: { 'scorecard': Blob }
  }): Observable<{ 'success'?: boolean }> {

    return this.sendImage$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'success'?: boolean }>) => r.body as { 'success'?: boolean })
    );
  }

  /**
   * Path part for operation getProfile
   */
  static readonly GetProfilePath = '/api/golftime/profile';

  /**
   * Get Profile.
   *
   * Get Profile.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'profile'?: Customer }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetProfilePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'profile'?: Customer }>;
      })
    );
  }

  /**
   * Get Profile.
   *
   * Get Profile.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfile(params?: {
  }): Observable<{ 'profile'?: Customer }> {

    return this.getProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'profile'?: Customer }>) => r.body as { 'profile'?: Customer })
    );
  }

  /**
   * Path part for operation updateProfileAddress
   */
  static readonly UpdateProfileAddressPath = '/api/golftime/profiles/addresses';

  /**
   * send profile address.
   *
   * send profile address
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfileAddress()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateProfileAddress$Response(params: {
    body: { 'email'?: string, 'tel'?: string, 'address1': string, 'address2'?: string, 'address3'?: string, 'postcode': string, 'city': string, 'country': string }
  }): Observable<StrictHttpResponse<{ 'customer'?: Customer, 'success'?: boolean, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.UpdateProfileAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'customer'?: Customer, 'success'?: boolean, 'error_description'?: string }>;
      })
    );
  }

  /**
   * send profile address.
   *
   * send profile address
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProfileAddress$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  updateProfileAddress(params: {
    body: { 'email'?: string, 'tel'?: string, 'address1': string, 'address2'?: string, 'address3'?: string, 'postcode': string, 'city': string, 'country': string }
  }): Observable<{ 'customer'?: Customer, 'success'?: boolean, 'error_description'?: string }> {

    return this.updateProfileAddress$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'customer'?: Customer, 'success'?: boolean, 'error_description'?: string }>) => r.body as { 'customer'?: Customer, 'success'?: boolean, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation sendProfileImage
   */
  static readonly SendProfileImagePath = '/api/golftime/profiles/images';

  /**
   * send profile image.
   *
   * send profile image
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendProfileImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendProfileImage$Response(params: {
    body: { 'profile': Blob }
  }): Observable<StrictHttpResponse<{ 'success'?: boolean }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.SendProfileImagePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'success'?: boolean }>;
      })
    );
  }

  /**
   * send profile image.
   *
   * send profile image
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendProfileImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  sendProfileImage(params: {
    body: { 'profile': Blob }
  }): Observable<{ 'success'?: boolean }> {

    return this.sendProfileImage$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'success'?: boolean }>) => r.body as { 'success'?: boolean })
    );
  }

  /**
   * Path part for operation getScorecards
   */
  static readonly GetScorecardsPath = '/api/golftime/scorecards/{type}';

  /**
   * List all submitted scorecards.
   *
   * List all submitted scorecards
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScorecards()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScorecards$Response(params: {
    type: string;
  }): Observable<StrictHttpResponse<{ 'currentRank'?: string, 'scorecards'?: Array<EventAttendee2> }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetScorecardsPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'currentRank'?: string, 'scorecards'?: Array<EventAttendee2> }>;
      })
    );
  }

  /**
   * List all submitted scorecards.
   *
   * List all submitted scorecards
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScorecards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScorecards(params: {
    type: string;
  }): Observable<{ 'currentRank'?: string, 'scorecards'?: Array<EventAttendee2> }> {

    return this.getScorecards$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'currentRank'?: string, 'scorecards'?: Array<EventAttendee2> }>) => r.body as { 'currentRank'?: string, 'scorecards'?: Array<EventAttendee2> })
    );
  }

  /**
   * Path part for operation getCurrentRank
   */
  static readonly GetCurrentRankPath = '/api/golftime/currents/{prize}/rank';

  /**
   * get current rank.
   *
   * get current rank
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCurrentRank()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentRank$Response(params: {
    prize: string;
  }): Observable<StrictHttpResponse<{ 'currentRank'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetCurrentRankPath, 'get');
    if (params) {
      rb.path('prize', params.prize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'currentRank'?: string }>;
      })
    );
  }

  /**
   * get current rank.
   *
   * get current rank
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCurrentRank$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCurrentRank(params: {
    prize: string;
  }): Observable<{ 'currentRank'?: string }> {

    return this.getCurrentRank$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'currentRank'?: string }>) => r.body as { 'currentRank'?: string })
    );
  }

  /**
   * Path part for operation getGtCategories
   */
  static readonly GetGtCategoriesPath = '/api/golftime/categories/{type}';

  /**
   * List the categories of the golf-time season.
   *
   * List the categories of the golf-time season
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGtCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtCategories$Response(params: {
    type: string;
  }): Observable<StrictHttpResponse<{ 'categories'?: Array<Prize> }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetGtCategoriesPath, 'get');
    if (params) {
      rb.path('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'categories'?: Array<Prize> }>;
      })
    );
  }

  /**
   * List the categories of the golf-time season.
   *
   * List the categories of the golf-time season
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGtCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtCategories(params: {
    type: string;
  }): Observable<{ 'categories'?: Array<Prize> }> {

    return this.getGtCategories$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'categories'?: Array<Prize> }>) => r.body as { 'categories'?: Array<Prize> })
    );
  }

  /**
   * Path part for operation getGtRanking
   */
  static readonly GetGtRankingPath = '/api/golftime/rankings/{category}';

  /**
   * List the ranking of the golf-time season for given category.
   *
   * List the ranking of the golf-time season for given category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGtRanking()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtRanking$Response(params: {
    category: string;
  }): Observable<StrictHttpResponse<{ 'players'?: Array<EventAttendee2> }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetGtRankingPath, 'get');
    if (params) {
      rb.path('category', params.category, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'players'?: Array<EventAttendee2> }>;
      })
    );
  }

  /**
   * List the ranking of the golf-time season for given category.
   *
   * List the ranking of the golf-time season for given category
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGtRanking$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtRanking(params: {
    category: string;
  }): Observable<{ 'players'?: Array<EventAttendee2> }> {

    return this.getGtRanking$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'players'?: Array<EventAttendee2> }>) => r.body as { 'players'?: Array<EventAttendee2> })
    );
  }

  /**
   * Path part for operation getGtProducts
   */
  static readonly GetGtProductsPath = '/api/golftime/products';

  /**
   * Returns a list of products.
   *
   * Returns a list of products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGtProducts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtProducts$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'products'?: Array<Box> }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetGtProductsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'products'?: Array<Box> }>;
      })
    );
  }

  /**
   * Returns a list of products.
   *
   * Returns a list of products
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGtProducts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtProducts(params?: {
  }): Observable<{ 'products'?: Array<Box> }> {

    return this.getGtProducts$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'products'?: Array<Box> }>) => r.body as { 'products'?: Array<Box> })
    );
  }

  /**
   * Path part for operation getGtPrices
   */
  static readonly GetGtPricesPath = '/api/golftime/monthly/prices';

  /**
   * Returns a list of products.
   *
   * Returns a list of products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGtPrices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtPrices$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'products'?: Array<Box> }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetGtPricesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'products'?: Array<Box> }>;
      })
    );
  }

  /**
   * Returns a list of products.
   *
   * Returns a list of products
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGtPrices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtPrices(params?: {
  }): Observable<{ 'products'?: Array<Box> }> {

    return this.getGtPrices$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'products'?: Array<Box> }>) => r.body as { 'products'?: Array<Box> })
    );
  }

  /**
   * Path part for operation getGtSponsors
   */
  static readonly GetGtSponsorsPath = '/api/golftime/sponsors/{position}';

  /**
   * Returns a list of products.
   *
   * Returns a list of products
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGtSponsors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtSponsors$Response(params: {
    position: string;
  }): Observable<StrictHttpResponse<{ 'sponsor.sponsor-gold.sponsor-header'?: Array<Box>, 'sponsor.sponsor-header.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-header'?: Array<Box>, 'sponsor.sponsor-gold.sponsor-page'?: Array<Box>, 'sponsor.sponsor-page.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-page'?: Array<Box> }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetGtSponsorsPath, 'get');
    if (params) {
      rb.path('position', params.position, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'sponsor.sponsor-gold.sponsor-header'?: Array<Box>, 'sponsor.sponsor-header.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-header'?: Array<Box>, 'sponsor.sponsor-gold.sponsor-page'?: Array<Box>, 'sponsor.sponsor-page.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-page'?: Array<Box> }>;
      })
    );
  }

  /**
   * Returns a list of products.
   *
   * Returns a list of products
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGtSponsors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGtSponsors(params: {
    position: string;
  }): Observable<{ 'sponsor.sponsor-gold.sponsor-header'?: Array<Box>, 'sponsor.sponsor-header.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-header'?: Array<Box>, 'sponsor.sponsor-gold.sponsor-page'?: Array<Box>, 'sponsor.sponsor-page.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-page'?: Array<Box> }> {

    return this.getGtSponsors$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'sponsor.sponsor-gold.sponsor-header'?: Array<Box>, 'sponsor.sponsor-header.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-header'?: Array<Box>, 'sponsor.sponsor-gold.sponsor-page'?: Array<Box>, 'sponsor.sponsor-page.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-page'?: Array<Box> }>) => r.body as { 'sponsor.sponsor-gold.sponsor-header'?: Array<Box>, 'sponsor.sponsor-header.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-header'?: Array<Box>, 'sponsor.sponsor-gold.sponsor-page'?: Array<Box>, 'sponsor.sponsor-page.sponsor-silver'?: Array<Box>, 'sponsor.sponsor-bronze.sponsor-page'?: Array<Box> })
    );
  }

  /**
   * Path part for operation getStripeKey
   */
  static readonly GetStripeKeyPath = '/api/golftime/stripe/key';

  /**
   * Returns the public key from stripe.
   *
   * Returns the public key from stripe
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStripeKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStripeKey$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'api_key'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetStripeKeyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'api_key'?: string }>;
      })
    );
  }

  /**
   * Returns the public key from stripe.
   *
   * Returns the public key from stripe
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStripeKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStripeKey(params?: {
  }): Observable<{ 'api_key'?: string }> {

    return this.getStripeKey$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'api_key'?: string }>) => r.body as { 'api_key'?: string })
    );
  }

  /**
   * Path part for operation getStripeSession
   */
  static readonly GetStripeSessionPath = '/api/golftime/stripes/{product}/session';

  /**
   * Returns a stripe session.
   *
   * Returns a stripe session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStripeSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStripeSession$Response(params: {
    product: string;
  }): Observable<StrictHttpResponse<{ 'session'?: StripeSession, 'reload'?: boolean }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.GetStripeSessionPath, 'get');
    if (params) {
      rb.path('product', params.product, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'session'?: StripeSession, 'reload'?: boolean }>;
      })
    );
  }

  /**
   * Returns a stripe session.
   *
   * Returns a stripe session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getStripeSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getStripeSession(params: {
    product: string;
  }): Observable<{ 'session'?: StripeSession, 'reload'?: boolean }> {

    return this.getStripeSession$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'session'?: StripeSession, 'reload'?: boolean }>) => r.body as { 'session'?: StripeSession, 'reload'?: boolean })
    );
  }

  /**
   * Path part for operation dUpdateStripeSessionPost
   */
  static readonly DUpdateStripeSessionPostPath = '/api/golftime/stripes/{stripe_session}/sessions/updates';

  /**
   * Update stripe session.
   *
   * Update stripe session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dUpdateStripeSessionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  dUpdateStripeSessionPost$Response(params: {
    stripe_session: string;
    body: { 'refresh_token'?: string }
  }): Observable<StrictHttpResponse<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, SeriesService.DUpdateStripeSessionPostPath, 'post');
    if (params) {
      rb.path('stripe_session', params.stripe_session, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * Update stripe session.
   *
   * Update stripe session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dUpdateStripeSessionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  dUpdateStripeSessionPost(params: {
    stripe_session: string;
    body: { 'refresh_token'?: string }
  }): Observable<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }> {

    return this.dUpdateStripeSessionPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>) => r.body as { 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string })
    );
  }

}
