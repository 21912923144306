import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Customer, Organization } from 'src/app/services/core/api/models';
import { EspVenue } from 'src/app/services/core/api/models/esp-venue';
import { TeetimesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
 
registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'gt-edit-friend',
  templateUrl: './edit-friend.component.html',
  styleUrls: ['./edit-friend.component.scss'],
})
export class EditFriendComponent implements OnInit {

  @Input()
  public friend: Customer;
  private customer: Customer;

  private isASGValid = true;
  private isAnredeValid = true;
  private isGolfclubValid = true;
  // private isEmailValid = true;
  private isFirstnameValid = true;
  private isLastnameValid = true;
  
  public golfclubs = [];
  public isDisabled = true;
  public success = '';
  public isLoading = false;
  private regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  data: any = {
    "background": "assets/imgs/background/Login_wide.jpg",
  };

  item = {
    'anrede': '',
    'firstname': '',
    'lastname': '',
    'golfclub': '',
    'asgnumber': '',
    'email': ''
  };

  constructor(
    private teetimeService: TeetimesService,
    private loadingService: LoadingSpinnerService,
    private clubSelectionService: ClubSelectionService,
    private alertController: AlertController,
    private authService: AuthService,
    private toastCtrl: ToastService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.authService.customer$.subscribe(customer => {
      this.customer = customer;
    });
    this.customer = this.authService.getCustomer();

    this.loadingService.isLoading.subscribe(loading => {
      this.isLoading = loading;
    });

    if (this.friend) {
      const club = this.clubSelectionService.findClubByCustomerId(this.friend.customer_id);

      this.item.firstname = this.friend.firstname;
      this.item.lastname = this.friend.lastname;
      this.item.anrede = this.friend.anrede === 'form.label_anrede_herr' ? 'm' : this.friend.anrede === 'form.label_anrede_frau' ? 'f' : '';
      this.item.email = this.friend.email;
      this.item.golfclub = club ? club.id : '';
      this.item.asgnumber = club ? this.friend.customer_id.substring(7) : '';
    }

    this.setGolfclubs();
  }

  onRegisterFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.validate()) {
      let friendId = null;
      const customer_id = this.item.golfclub + this.fix6DigitsNumber(this.item.asgnumber);
      if (this.friend && this.friend.id) {
        friendId = this.friend.id;
      }
      const params = {
        body: {
          customer: friendId,
          email: this.item.email,
          anrede: this.item.anrede,
          firstname: this.item.firstname,
          lastname: this.item.lastname,
          customer_id: customer_id
        }
      }
      this.teetimeService.updateFriend(params).subscribe(s => {
        this.success = s.msg;
        if (this.success === 'ERR') {
          this.toastCtrl.presentToast(s.error_description, 'danger');
        } else {
          this.authService.setCustomer(s.customer);
          this.friend = s.friend;
          this.dismiss();
        }
      });
    }
  }

  fix6DigitsNumber(number: string) {
    const length = number.length;
    for (let index = length; index < 6; index++) {
      number = '0' + number;
    }
    return number;
  }

  validate(): boolean {
    this.isASGValid = true;
    this.isGolfclubValid = true;
    this.isAnredeValid = true;
    // this.isEmailValid = true;
    this.isFirstnameValid = true;
    this.isLastnameValid = true;

    if (!this.item.golfclub || this.item.golfclub.length === 0) {
      this.isGolfclubValid = false;
    }

    if (!this.item.anrede || this.item.anrede.length === 0) {
      this.isAnredeValid = false;
    }

    if (!this.item.firstname || this.item.firstname.length === 0) {
      this.isFirstnameValid = false;
    }

    if (!this.item.lastname || this.item.lastname.length === 0) {
      this.isLastnameValid = false;
    }

    if (!this.item.asgnumber || this.item.asgnumber.length === 0) {
      this.isASGValid = false;
    }

    // this.isEmailValid = this.regex.test(this.item.email);

    return this.isGolfclubValid && this.isASGValid &&
      this.isFirstnameValid &&
      this.isLastnameValid;
  }

  save() {
    this.dismiss();
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'friend': this.friend
    });
  }

  setGolfclubs() {
    const clubs = [
      { id: '0410001', name: 'Ascona' },
      { id: '0410002', name: 'Bad Ragaz' },
      { id: '0410003', name: 'Basel' },
      { id: '0410004', name: 'Blumisberg' },
      { id: '0410005', name: 'Bodensee Weissensberg' },
      { id: '0410006', name: 'Les Bois' },
      { id: '0410007', name: 'Bonmont' },
      { id: '0410008', name: 'Bossey' },
      { id: '0410009', name: 'Breitenloo' },
      { id: '0410010', name: 'Crans-sur-Sierre' },
      { id: '0410011', name: 'Davos' },
      { id: '0410012', name: 'Domaine Impérial' },
      { id: '0410013', name: 'Engadine' },
      { id: '0410014', name: 'Erlen' },
      { id: '0410015', name: 'Esery' },
      { id: '0410016', name: 'Genève' },
      { id: '0410017', name: 'Hittnau-Zürich' },
      { id: '0410018', name: 'Interlaken' },
      { id: '0410019', name: 'La Largue' },
      { id: '0410020', name: 'Lausanne' },
      { id: '0410021', name: 'Lenzerheide' },
      { id: '0410022', name: 'Lucerne' },
      { id: '0410023', name: 'Lugano' },
      { id: '0410024', name: 'Maison Blanche' },
      { id: '0410025', name: 'Payerne' },
      { id: '0410026', name: 'Neuchâtel' },
      { id: '0410027', name: 'Montreux' },
      { id: '0410028', name: 'OSGC' },
      { id: '0410030', name: 'Sedrun' },
      { id: '0410031', name: 'Schönenberg' },
      { id: '0410032', name: 'Verbier' },
      { id: '0410033', name: 'Villars' },
      { id: '0410034', name: 'Wallenried' },
      { id: '0410035', name: 'Zürich-Zumikon' },
      { id: '0410037', name: 'Sempachersee' },
      { id: '0410038', name: 'Gstaad' },
      { id: '0410039', name: 'Entfelden' },
      { id: '0410040', name: 'Ennetsee' },
      { id: '0410041', name: 'Alvaneu Bad' },
      { id: '0410042', name: 'Domat / Ems' },
      { id: '0410043', name: 'Schinznach Bad' },
      { id: '0410044', name: 'Arosa' },
      { id: '0410045', name: 'Bürgenstock' },
      { id: '0410046', name: 'Gruyère' },
      { id: '0410047', name: 'Dolder' },
      { id: '0410048', name: 'Riederalp' },
      { id: '0410049', name: 'Sierre' },
      { id: '0410050', name: 'Vulpera' },
      { id: '0410051', name: 'Appenzell' },
      { id: '0410052', name: 'Bubikon' },
      { id: '0410053', name: 'Wylihof' },
      { id: '0410054', name: 'Küssnacht' },
      { id: '0410055', name: 'Engelberg-Titlis' },
      { id: '0410056', name: 'Flühli Sörenberg' },
      { id: '0410057', name: 'Gotthard Realp' },
      { id: '0410058', name: 'Goldenberg' },
      { id: '0410059', name: 'Nuolen' },
      { id: '0410060', name: 'Les Coullaux' },
      { id: '0410061', name: 'Domaine du Brésil' },
      { id: '0410062', name: 'Lavaux' },
      { id: '0410064', name: 'Sion' },
      { id: '0410065', name: 'Source du Rhône' },
      { id: '0410066', name: 'Limpachtal' },
      { id: '0410067', name: 'Emmental' },
      { id: '0410068', name: 'Ybrig' },
      { id: '0410069', name: 'Heidental' },
      { id: '0410070', name: 'Thunersee' },
      { id: '0410071', name: 'Aaretal' },
      { id: '0410072', name: 'La Côte' },
      { id: '0410073', name: 'Lipperswil' },
      { id: '0410074', name: 'Waldkirch' },
      { id: '0410075', name: 'Losone' },
      { id: '0410076', name: 'Lägern' },
      { id: '0410077', name: 'Vuissens' },
      { id: '0410080', name: 'Leuk' },
      { id: '0410082', name: 'Unterengstringen' },
      { id: '0410083', name: 'Engadine Golf Club' },
      { id: '0410084', name: 'Bern' },
      { id: '0410085', name: 'Rastenmoos' },
      { id: '0410086', name: 'Matterhorn' },
      { id: '0410087', name: 'Winterberg' },
      { id: '0410088', name: 'Kyburg' },
      { id: '0410089', name: 'Klosters' },
      { id: '0410090', name: 'Heidiland' },
      { id: '0410092', name: 'Fricktal' },
      { id: '0410093', name: 'Brigels' },
      { id: '0410094', name: 'Axenstein' },
      { id: '0410095', name: 'Oberkirch' },
      { id: '0410096', name: 'Gams' },
      { id: '0410097', name: 'Rheinfelden' },
      { id: '0410098', name: 'Weid Hauenstein' },
      { id: '0410099', name: 'Sagogn' },
      { id: '0410100', name: 'Laufental' },
      { id: '0410101', name: 'Augwil' },
      { id: '0412000', name: 'ASGI' },
      { id: '0417000', name: 'ASG Migros' },
      { id: '0497719', name: 'Markgräflerland, GC' },
      { id: '0497734', name: 'Obere Alp, GC' },
      { id: '0497742', name: 'Rheinblick, GC' }
    ];
    clubs.sort((a,b) => a.name.localeCompare(b.name));

    this.golfclubs.push({
      id: '0000000',
      name: 'Unbekannt'
    });
    clubs.forEach(element => {
      this.golfclubs.push(element);
    });
  }

  scrolling(event) {
    setTimeout(()=> {
      
    });
  }

}
