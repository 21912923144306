import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';

@Component({
  selector: 'cs-register-confirm',
  templateUrl: 'register-confirm.page.html',
  styleUrls: ['register-confirm.page.scss'],
})
export class RegisterConfirmPage implements OnInit {
  data: any = {
    "toolbarTitle"        : "Confirm account",
    "confirm"             : "confirm",
    "title"               : "Confirm your account",
    "background"          : "assets/imgs/background/Login_wide.jpg",
    "email"               : "Your e-mail address",
    "code"                : "Your activation code",
    "password1"           : "Your Password",
    "password2"           : "Your Password",
    "lableEmail"          : "E-MAIL",
    "lableCode"           : "Activation Code",
    "lablePassword1"      : "Password",
    "lablePassword2"      : "Confirm Password",
    "lableSuccess"        : "Registration was successfull, please login.",
  };

  private isEmailValid = true;
  private isCodeValid = true;
  private isPassword1Valid = true;
  private isPassword2Valid = true;
  private isLoading = false;
  private success = '';

  private regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  item = {
    'email': '',
    'code': '',
    'password1': '',
    'password2': ''
  };

  constructor(
    private loginService: LoginService,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.loadingService.isLoading.subscribe(loading => {
      this.isLoading = loading;
    });

    this.item.code = this.route.snapshot.queryParams["code"];
    this.item.email = this.route.snapshot.queryParams["email"];
  }

  onRegisterFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.validate()) {
      const params = {
        body: {
          email: this.item.email.replace(' ', ''),
          code: this.item.code.replace(' ', ''),
          password1: this.item.password1.replace(' ', ''),
          password2: this.item.password2.replace(' ', '')
        }
      }
      this.loginService.doActivatePost(params).subscribe(s => {
        this.success = s.error;
        if (s.error === 'ERR') {
          this.toastCtrl.presentToast(s.error_description, 'danger');
        } else {
          const model = {
            body: {
              username: this.item.email.replace(' ', ''),
              password: this.item.password1.replace(' ', '')
            }
          };
          const loginUser$ = this.loginService.doLoginPost(model);
          loginUser$.subscribe(
            data => {
              if (data.access_token != '') {
                this.authService.SetToken(data);
                
              }
              this.router.navigate(['']);
            },
            e => { }
          );
        }
      });
    }
  }

  validate(): boolean {
    this.isEmailValid = true;
    this.isCodeValid = true;
    this.isPassword1Valid = true;
    this.isPassword2Valid = true;

    if (!this.item.code || this.item.code.length === 0) {
      this.isCodeValid = false;
    }

    if (!this.item.password1 || this.item.password1.length < 6) {
      this.isPassword1Valid = false;
    }

    if (!this.item.password2 || this.item.password2 !== this.item.password1) {
      this.isPassword2Valid = false;
    }

    this.isEmailValid = this.regex.test(this.item.email);

    return this.isEmailValid &&
      this.isCodeValid &&
      this.isPassword1Valid &&
      this.isPassword2Valid;
  }
}
