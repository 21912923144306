import { Injectable } from '@angular/core';

declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  private prevRoute: string = null;

  constructor() { }

  sendEvent(params: { currentUrl?: string, aditionalParams?: string } = { currentUrl: window.location.href, aditionalParams: null }): void {

    if (!params) {
      params = { currentUrl: window.location.href };
    }
    if (!params.currentUrl) {
      params.currentUrl = window.location.href;
    }

    // if we do not added an aditional Parameter, set global prevRoute to null, so it will be ignored
    if (!params || !params.aditionalParams) {
      this.prevRoute = null;
    }

    // take prev Route default from session var
    let prevRoute = sessionStorage.getItem('currentRoute');

    // if we previously stored aditional parameters, take the global prevRoute
    if (this.prevRoute) {
      prevRoute = this.prevRoute;
    }

    // if we add aditional parameters, add them to the current route and store them globally
    if (params && params.aditionalParams) {
      params.currentUrl = params.currentUrl + params.aditionalParams;
      this.prevRoute = params.currentUrl;
    }

    // ignore some user related info like token refresh from ADFS etc.
    // if (params.currentUrl.indexOf('/auth-callback#access_token') !== -1) {
    //   params.currentUrl = '/auth-callback#access_token';
    // }
    // if (params.currentUrl.indexOf('/auth-callback?client-request-id') !== -1) {
    //   params.currentUrl = '/auth-callback?client-request-id';
    // }
    // if (params.currentUrl.indexOf('/complete-registration?code') !== -1) {
    //   params.currentUrl = '/complete-registration';
    // }
    // if (params.currentUrl.indexOf('/confirm-email?email') !== -1) {
    //   params.currentUrl = '/confirm-email';
    // }
    // if (params.currentUrl.indexOf('/reset-password?code') !== -1) {
    //   params.currentUrl = '/reset-password';
    // }

    // send event to matomo.repower.com
    const mtm = window._paq;
    if (mtm === undefined) {
      return;
    }
    mtm.push(['setReferrerUrl', prevRoute]);
    mtm.push(['setCustomUrl', params.currentUrl]);

    // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
    mtm.push(['deleteCustomVariables', 'page']);
    // mtm.push(['setGenerationTimeMs', 0]);
    mtm.push(['trackPageView']);

    // make Matomo aware of newly added content
    const content = document.getElementById('content');
    mtm.push(['MediaAnalytics::scanForMedia', content]);
    mtm.push(['FormAnalytics::scanForForms', content]);
    mtm.push(['trackContentImpressionsWithinNode', content]);
    // mtm.push(['setVisitorCookieTimeout', '10']);
    // mtm.push(['setReferralCookieTimeout', '5']);
    // mtm.push(['setSessionCookieTimeout', '1']);

    // setVisitorCookieTimeout( seconds ) - the default is 13 months
    // setReferralCookieTimeout( seconds ) - the default is 6 months
    // setSessionCookieTimeout( seconds ) - the default is 30 minutes

  }
}
