import { HomeComponent } from './home/home.component';
import { StripeJavaScriptComponent } from './payment/stripe-java-script/stripe-java-script.component';
import { AuthCallbackPageComponent } from './login/auth-callback-page/auth-callback-page.component';
import { StripeSuccessComponent } from './payment/stripe-success/stripe-success.component';
import { StripeErrorComponent } from './payment/stripe-error/stripe-error.component';
import { TeetimesComponent } from './teetimes/timetable/teetimes.component';
import { EventListComponent } from './events/event-list/event-list.component';
import { LoginLayout2Page } from './login/login-layout-2/login-layout-2.page';
import { ProfileOverviewComponent } from './profile/profile-overview/profile-overview.component';
import { RegisterLayout1Page } from './login/register-layout-1/register-layout-1.page';
import { RegisterConfirmPage } from './login/register-confirm/register-confirm.page';
import { ForgotPasswordPage } from './login/forgot-password/forgot-password.page';
import { ForgotPasswordConfirmPage } from './login/forgot-password-confirm/forgot-password-confirm.page';
import { MonthlyPricesComponent } from './monthly-prices/monthly-prices.component';
import { ZeileComponent } from './teetimes/zeile/zeile.component';
import { FriendsComponent } from './teetimes/friends/friends.component';
import { EditFriendComponent } from './teetimes/edit-friend/edit-friend.component';
import { EditAddressComponent } from './profile/edit-address/edit-address.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { ContactFormComponent } from './contact-form/contact-form.component';

export const pages: any[] = [
  HomeComponent,
  StripeJavaScriptComponent,
  AuthCallbackPageComponent,
  StripeSuccessComponent,
  StripeErrorComponent,
  TeetimesComponent,
  ZeileComponent,
  FriendsComponent,
  EditFriendComponent,
  EditAddressComponent,
  EventListComponent,
  LoginLayout2Page,
  RegisterLayout1Page,
  RegisterConfirmPage,
  ForgotPasswordPage,
  ForgotPasswordConfirmPage,
  ProfileOverviewComponent,
  MonthlyPricesComponent,
  SponsorsComponent,
  ContactFormComponent
];

export * from './home/home.component';
export * from './login/auth-callback-page/auth-callback-page.component';
export * from './payment/stripe-java-script/stripe-java-script.component';
export * from './payment/stripe-success/stripe-success.component';
export * from './payment/stripe-error/stripe-error.component';
export * from './teetimes/timetable/teetimes.component';
export * from './events/event-list/event-list.component';
export * from './login/login-layout-2/login-layout-2.page';
export * from './profile/profile-overview/profile-overview.component';
export * from './login/register-layout-1/register-layout-1.page';
export * from './login/register-confirm/register-confirm.page';
export * from './login/forgot-password/forgot-password.page';
export * from './login/forgot-password-confirm/forgot-password-confirm.page';
export * from './monthly-prices/monthly-prices.component';
export * from './teetimes/zeile/zeile.component';
export * from './teetimes/friends/friends.component';
export * from './teetimes/edit-friend/edit-friend.component';
export * from './profile/edit-address/edit-address.component';
export * from './sponsors/sponsors.component';
export * from './contact-form/contact-form.component';
