import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from 'src/app/services/menu.service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { TokenHelperService } from 'src/app/shared/auth/token-helper-service.service';
import { Renderer2 } from '@angular/core';
import { SponsorsService } from 'src/app/services/sponsors.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  data: any;
  public username = '';
  private debugMode = false;

  public left = {};
  public middle = {};
  public right = {};
  public offset = 0;
  public menuItems = [];

  @ViewChild('mainnavbar') navElement: ElementRef;
  @ViewChild('scrollmenu') scrollmenu: ElementRef;

  constructor(
    private _authService: AuthService,
    private jwt_decode: TokenHelperService,
    private menuService: MenuService,
    private translationService: TranslateService,
    private iab: InAppBrowser,
    private element: ElementRef,
    private renderer: Renderer2,
    private _sponsorService: SponsorsService
  ) {}

  ngOnInit() {
    this.data = {
      'logo': '/assets/imgs/logo/iphone_screen_logo_weiss.png',
      'bgImage': '/assets/imgs/background/26_bw1.jpg',
      'introText': '<span>Das erste Virtual Golf Ranking<br/>weltweit kann dich</span> <span class=\'text-color-accent\'>jedes</span><br/><span>HCP wirksame Turnier zum Sieg führen</span>',
      'menuItems': [
        {
          'icon': 'icon-information-outline',
          'name': 'page.home.label.reglement',
          'click': 'browser',
          'link': 'https://golf-time.ch/reglement'
        },
        {
          'icon': 'icon-timer',
          'name': 'page.home.label.teetime',
          'click': 'teetimes'
        },
        {
          'icon': 'icon-camera',
          'name': 'page.home.label.scorecard',
          'click': 'scorecard'
        },
        {
          'icon': 'icon-polymer',
          'name': 'page.home.label.sponsors',
          'click': 'sponsors'
        },
        {
          'icon': 'icon-format-align-center',
          'name': 'page.home.label.agb',
          'click': 'browser',
          'link': 'https://golf-time.ch/agb'
        }
      ]
    };

    this._sponsorService.loadSponsors();
    this._authService.loggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        const claims = this._authService.getToken();
        const decoded: any = this.jwt_decode.parseJwt(claims);
        this.username = decoded.name;
      }
      this.claims();
    });

    this.debugMode = this._authService.containRole('MODE_DEBUG');
    
  }

  ionViewDidEnter() {

    this.offset = this.navElement.nativeElement.offsetWidth / 2;
    
    this.left = this.data.menuItems[0];
    this.middle = this.data.menuItems[1];
    this.right = this.data.menuItems[2];

    setTimeout(() => {
      this.getWidth();
      this.scrollToItem(0);
    }, 0);
  }

  scrollToItem(index: number, direction = 1) {
    const index_left = index > 0 ? index - 1 : 0;
    const index_mid = index;
    const index_right = this.data.menuItems.length <= (index + 1) ? this.data.menuItems.length - 1 : index + 1;

    if (this.data.menuItems.length <= index) {
      index = this.data.menuItems.length-1;
    }
    if (index < 0) {
      return;
    }

    this.left = this.data.menuItems[index_left];
    this.middle = this.data.menuItems[index_mid];
    this.right = this.data.menuItems[index_right];

    const item = this.data.menuItems[index];
    const width = this.widthByItem(index+1);
    this.scrollmenu.nativeElement.scrollLeft = width;

    let index_new = index + (1 * direction);
    if (index_new >= this.data.menuItems.length) {
      direction = -1;
      index_new = index - 1;
    }

    setTimeout(() => {
      this.scrollToItem(index_new, direction);
    }, 350);
  }

  widthByItem(item) {
    // 1 --- 0 medium + 0 small
    // 2 --- 1 medium + 0 small
    // 3 --- 1 medium + 1 small
    // 4 --- 1 medium + 2 small
    var medium = this.scrollmenu.nativeElement.querySelector(".side");
    var big = this.scrollmenu.nativeElement.querySelector(".middle");
    var small = this.scrollmenu.nativeElement.querySelector(".menu-item:not(.side):not(.middle)");

    const style_medium = window.getComputedStyle(medium);
    const marginMedium = +(style_medium.marginLeft.replace('px', ''));

    const style_small = window.getComputedStyle(small);
    const marginSmall = +(style_small.marginLeft.replace('px', ''));

    const mediumSize = medium.offsetWidth + (marginMedium * 2);
    const smallSize = small.offsetWidth + (marginSmall * 2);

    const numMedium = item > 1 ? 1 : 0;
    const numSmall = item > 2 ? item - 2 : 0;

    return (numMedium * mediumSize) + (numSmall * smallSize);
  }



  itemByWidth(width) {

    // 1 --- 1/2 big + 0 medium + 0 small
    // 2 --- 1/2 big + 1 medium + 0 small
    // 3 --- 1/2 big + 1 medium + 1 small
    // 4 --- 1/2 big + 1 medium + 2 small
    var medium = this.scrollmenu.nativeElement.querySelector(".side");
    var big = this.scrollmenu.nativeElement.querySelector(".middle");
    var small = this.scrollmenu.nativeElement.querySelector(".menu-item:not(.side):not(.middle)");

    const style_big = window.getComputedStyle(big);
    const marginBig = +(style_big.marginLeft.replace('px', ''));

    const style_medium = window.getComputedStyle(medium);
    const marginMedium = +(style_medium.marginLeft.replace('px', ''));

    const style_small = window.getComputedStyle(small);
    const marginSmall = +(style_small.marginLeft.replace('px', ''));

    const bigSize = big.offsetWidth + (marginBig * 2);
    const mediumSize = medium.offsetWidth + (marginMedium * 2);
    const smallSize = small.offsetWidth + (marginSmall * 2);

    let index = 0;
    let width_new = width - (bigSize/2);
    if (width_new <= 0) {
      width_new = 0;
      index = 0;
    } else {
      width_new = width_new - mediumSize;
      if (width_new <= 0) {
        width_new = 0;
      }
      index = 1;
      index = index + Math.ceil(width_new / smallSize);
    }
    return index;
  }

  getWidth() {
    const len =  this.scrollmenu.nativeElement.children.length;
    if (len > 0) {
      const ele = this.scrollmenu.nativeElement.children[1];
      const w = ele.offsetWidth;
      const style_ele = window.getComputedStyle(ele);
      const margin = +(style_ele.marginLeft.replace('px', ''));
      
      this.offset -= (w/2 + margin);
    }
  }

  onContainerScroll($event) {
    const item_index = this.itemByWidth($event.target.scrollLeft);
    const len =  this.scrollmenu.nativeElement.children.length;
    if (item_index > 0) {
      this.left = this.data.menuItems[item_index-1];
    }
    this.middle = this.data.menuItems[item_index];
    if (item_index < (len-1)) {
      this.right = this.data.menuItems[item_index+1];
    }
  }

  callFunction(item: any) {
    if (item.click === 'scorecard') {
      this.scorecard();
    }
    if (item.link) {
      this.displayBrowser(item.link);
    }
    if (item.click === 'teetimes') {
      this.teetimes();
    }
    if (item.click === 'sponsors') {
      this.sponsors();
    }
  }

  sponsors() {
    this.menuService.navigate('/sponsors');
  }

  teetimes() {
    this.menuService.navigate('/teetimes');
  }

  scorecard() {
    let url = 'series/upload-image';
    if (!this._authService.containRole('ROLE_SERIES')) {
      url = '/payments';
    }
    this.menuService.navigate(url);
  }

  displayBrowser(link) {
    const target = '_blank';
    const options = {};
    this.iab.create(link, target, options);
  }

  claims() {
    const claims = this._authService.getToken();
    const decoded: any = this.jwt_decode.parseJwt(claims);
    return JSON.stringify(decoded);
  }

  scrolling(event) {
    setTimeout(()=> {
      
    });
  }
}
