/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Customer } from '../models/customer';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation doResetPasswordPost
   */
  static readonly DoResetPasswordPostPath = '/api/public/resets/passwords';

  /**
   * this call will do a reset password.
   *
   * this call will do a reset password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doResetPasswordPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doResetPasswordPost$Response(params: {
    body: { 'email': string }
  }): Observable<StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * this call will do a reset password.
   *
   * this call will do a reset password
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doResetPasswordPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doResetPasswordPost(params: {
    body: { 'email': string }
  }): Observable<{ 'error'?: string, 'error_description'?: string }> {

    return this.doResetPasswordPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>) => r.body as { 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation doActivateResetPasswordPost
   */
  static readonly DoActivateResetPasswordPostPath = '/api/public/activates/resets/passwords';

  /**
   * this call will reset password for a registered account.
   *
   * this call will reset password for a registered account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doActivateResetPasswordPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doActivateResetPasswordPost$Response(params: {
    body: { 'email': string, 'code': string, 'password1': string, 'password2': string }
  }): Observable<StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoActivateResetPasswordPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * this call will reset password for a registered account.
   *
   * this call will reset password for a registered account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doActivateResetPasswordPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doActivateResetPasswordPost(params: {
    body: { 'email': string, 'code': string, 'password1': string, 'password2': string }
  }): Observable<{ 'error'?: string, 'error_description'?: string }> {

    return this.doActivateResetPasswordPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>) => r.body as { 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation doRegisterPost
   */
  static readonly DoRegisterPostPath = '/api/public/registers';

  /**
   * Returns success / fail if the register was able to send e-mail.
   *
   * Returns success / fail if the register was able to send e-mail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doRegisterPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doRegisterPost$Response(params: {
    body: { 'email': string, 'anrede': string, 'firstname': string, 'lastname': string }
  }): Observable<StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * Returns success / fail if the register was able to send e-mail.
   *
   * Returns success / fail if the register was able to send e-mail
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doRegisterPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doRegisterPost(params: {
    body: { 'email': string, 'anrede': string, 'firstname': string, 'lastname': string }
  }): Observable<{ 'error'?: string, 'error_description'?: string }> {

    return this.doRegisterPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>) => r.body as { 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation doActivatePost
   */
  static readonly DoActivatePostPath = '/api/public/activates';

  /**
   * this call will activate a registered account.
   *
   * this call will activate a registered account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doActivatePost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doActivatePost$Response(params: {
    body: { 'email': string, 'code': string, 'password1': string, 'password2': string }
  }): Observable<StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoActivatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * this call will activate a registered account.
   *
   * this call will activate a registered account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doActivatePost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doActivatePost(params: {
    body: { 'email': string, 'code': string, 'password1': string, 'password2': string }
  }): Observable<{ 'error'?: string, 'error_description'?: string }> {

    return this.doActivatePost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'error'?: string, 'error_description'?: string }>) => r.body as { 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation doLoginPost
   */
  static readonly DoLoginPostPath = '/api/public/logins';

  /**
   * Returns a list of venues by club.
   *
   * Returns a list of venues by club
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doLoginPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doLoginPost$Response(params: {
    body: { 'username': string, 'password': string }
  }): Observable<StrictHttpResponse<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoLoginPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * Returns a list of venues by club.
   *
   * Returns a list of venues by club
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doLoginPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doLoginPost(params: {
    body: { 'username': string, 'password': string }
  }): Observable<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }> {

    return this.doLoginPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>) => r.body as { 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation doRefreshTokenPost
   */
  static readonly DoRefreshTokenPostPath = '/api/public/refreshes/tokens';

  /**
   * Returns a new refresh token.
   *
   * Returns a new refresh token
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doRefreshTokenPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doRefreshTokenPost$Response(params: {
    body: { 'refresh_token': string }
  }): Observable<StrictHttpResponse<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoRefreshTokenPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * Returns a new refresh token.
   *
   * Returns a new refresh token
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doRefreshTokenPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doRefreshTokenPost(params: {
    body: { 'refresh_token': string }
  }): Observable<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }> {

    return this.doRefreshTokenPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>) => r.body as { 'customer'?: Customer, 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string })
    );
  }

  /**
   * Path part for operation doLogoutPost
   */
  static readonly DoLogoutPostPath = '/api/public/logouts';

  /**
   * logout a user.
   *
   * logout a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `doLogoutPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doLogoutPost$Response(params: {
    body: { 'refresh_token': string }
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DoLogoutPostPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * logout a user.
   *
   * logout a user
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `doLogoutPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  doLogoutPost(params: {
    body: { 'refresh_token': string }
  }): Observable<void> {

    return this.doLogoutPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation dPublicUpdateStripeSessionPost
   */
  static readonly DPublicUpdateStripeSessionPostPath = '/api/public/stripes/{stripe_session}/sessions/updates/publics';

  /**
   * Update stripe session.
   *
   * Update stripe session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dPublicUpdateStripeSessionPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  dPublicUpdateStripeSessionPost$Response(params: {
    stripe_session: string;
    body: { 'refresh_token'?: string }
  }): Observable<StrictHttpResponse<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>> {

    const rb = new RequestBuilder(this.rootUrl, LoginService.DPublicUpdateStripeSessionPostPath, 'post');
    if (params) {
      rb.path('stripe_session', params.stripe_session, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>;
      })
    );
  }

  /**
   * Update stripe session.
   *
   * Update stripe session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dPublicUpdateStripeSessionPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  dPublicUpdateStripeSessionPost(params: {
    stripe_session: string;
    body: { 'refresh_token'?: string }
  }): Observable<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }> {

    return this.dPublicUpdateStripeSessionPost$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string }>) => r.body as { 'access_token'?: string, 'expires_in'?: number, 'refresh_expires_in'?: number, 'refresh_token'?: string, 'token_type'?: string, 'session_state'?: string, 'scope'?: string, 'id_token'?: string, 'error'?: string, 'error_description'?: string })
    );
  }

}
