import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { CameraPhoto } from '@capacitor/core';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Customer } from 'src/app/services/core/api/models';
import { SeriesService, TeetimesService } from 'src/app/services/core/api/services';
import { PhotoService } from 'src/app/services/photo.service';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { environment } from 'src/environments/environment';
import { EditFriendComponent } from '../../teetimes/edit-friend/edit-friend.component';
import { EditAddressComponent } from '../edit-address/edit-address.component';

@Component({
  selector: 'app-profile-overview',
  templateUrl: 'profile-overview.component.html',
  styleUrls: ['profile-overview.component.scss'],
})
export class ProfileOverviewComponent implements OnInit {
  data: any = {};

  pccUserId = null;
  isPCCUsernameValid = true;
  isPCCPasswordValid = true;
  
  isHeaderVisible = false;
  isLoading = false;

  customer: Customer;

  constructor(
    private alertController: AlertController,
    private teetimeService: TeetimesService,
    private seriesService: SeriesService, 
    private authService: AuthService,
    private photoService: PhotoService,
    private loadingService: LoadingSpinnerService,
    private toastCtrl: ToastService,
    private translationService: TranslateService,
    public modalController: ModalController,
    private clubSelectionService: ClubSelectionService
  ) { }

  ngOnInit() {
    // this.authService.customer$.subscribe(customer => {
    //   this.customer = customer;
    //   this.initData(customer);
    // });
    this.loadingService.isLoading.subscribe(loading => {
      this.isLoading = loading;
    });
    this.pccUserId = this.authService.getAttribute('PCCUserId');
    this.loadCustomer();
  }

  loadCustomer() {
    this.data = {
      "headerImage": 'assets/imgs/background/26_bw1.jpg',
      "image": 'assets/imgs/avatar/26.jpg',
      "imageSelected": false,
      "autorInfo": [
        {
          "id": 1,
          "subtitle1": '',
          "subtitle2": '',
          "title": '',
          "iconMore": "chevron-down",
          "expandItems": []
        }
      ],
      "pcc": {
        "username": '',
        "password": '',
        "img": '/assets/imgs/avatar/pcc.png'
      },
      "friends": [],
      "items": []
    };
    this.seriesService.getProfile().subscribe(response => {
      this.authService.setCustomer(response.profile);
      this.customer = response.profile;
      this.initData(response.profile);
    });
  }

  doRefresh(event) {
    event.target.complete();
    this.loadCustomer();
  }

  initData(customer: any) {

    const womans = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
    const mens = [21, 22, 23];

    if (customer) {
      let photo = 'assets/imgs/avatar/26.jpg';
      let photoSelected = false;
      if (customer.media) {
        photo = environment.api.host + customer.media['public_urls']['big']['url'];
        photoSelected = true;
      }

      const addresses = [];
      customer.addresses.forEach(element => {
        let phone = element.phone;
        if (!phone || phone.length === 0) {
          phone = 'page.profile.phone.missing';
        }
        const address = {
          "id": element.id,
          "iconPhone": "call",
          "iconMail": "mail-open",
          "iconGlobe": "globe",
          "phone": phone,
          "mail": element.email,
          "globe": element.address1 + ', ' + element.postcode + ' ' + element.city,
          "content": 'address.type.' + element.type,
        };
        addresses.push(address);
      });
      if (addresses.length === 0) {
        const address = {
          "id": 0,
          "iconPhone": "call",
          "iconMail": "mail-open",
          "iconGlobe": "globe",
          "phone": 'page.profile.phone.missing',
          "mail": customer.email,
          "globe": 'page.profile.address.missing',
          "content": 'address.type.0',
        };
        addresses.push(address);
      }

      const bookings = [];
      const customer_bookings = this.normalizeBookings(customer);
      customer_bookings.forEach(element => {    
        const booking = {
          "booking": element,
          "title": element.person_name,
          "subtitle": element.booking_id,
          "price": element.organization.name + ' (' + element.venueid + ') ' + element.booking_time_from,
          "deleted": element.deleted
        };
        bookings.push(booking);
      });

      const friends = [];
      customer.my_friends.forEach(friend => {
        let picArr = mens;
        if (friend.anrede === 'form.label_anrede_frau') {
          picArr = womans;
        }
        let fPhoto = 'assets/imgs/avatar/' + picArr[0] + '.jpg';
        if (friend.media) {
          fPhoto = environment.api.host + friend.media['public_urls']['big']['url'];
        }
        const nFriend = {
          "id": friend.id,
          "image": fPhoto,
          "title": friend.firstname + ' ' + friend.lastname.substr(0,1) + '.'
        };
        friends.push(nFriend);
      });
      this.data = {
        "headerImage": 'assets/imgs/background/26_bw1.jpg',
        "image": photo,
        "imageSelected": photoSelected,
        "autorInfo": [
          {
            "id": 1,
            "subtitle1": this.golfclub(customer.customer_id),
            "subtitle2": this.number(customer.customer_id),
            "title": customer.firstname + ' ' + customer.lastname,
            "iconMore": "chevron-down",
            "expandItems": addresses
          }
        ],
        "pcc": {
          "username": '',
          "password": '',
          "img": '/assets/imgs/avatar/pcc.png'
        },
        "friends": friends,
        "items": bookings
      };
    }
  }

  normalizeBookings(customer): any[] {
    const bookings = [];
    const bookingsDeleted = [];
    customer.pcc_bookings.forEach(element => {
      if (!element.deleted) {
        element.sort_date = element.booking_date + ' ' + element.booking_time_from;
        bookings.push(element);
      } else {
        bookingsDeleted.push(element);
      }
    });

    bookings.sort((a,b) => b.sort_date.localeCompare(a.sort_date));

    bookingsDeleted.forEach(element => {
      const booking = bookings.find(x => x.booking_id === element.booking_id);
      if (booking) {
        booking.deleted = true;
      }
    });

    return bookings;
  }

  golfclub(customer_id) {
    const club = this.clubSelectionService.findClubByCustomerId(customer_id);
    if (!club) {
      return 'page.teetime.friend.noclub';
    }
    return club.name;
  }

  number(customer_id) {
    if (!customer_id || customer_id.length < 13) {
      return '';
    }
    return customer_id.substring(7);
  }

  connectPCC() {
    if (this.validatePCC()) {
      // send info to server

      const params = {
        body: {
          username: this.data.pcc.username,
          password: this.data.pcc.password
        }
      }
      this.teetimeService.linkPcc(params).subscribe(response => {
        if (response.error_description) {
          // this.photoService.saveCapturedPhoto(photo);
          this.toastCtrl.presentToast(response.error_description, 'danger');
        } else {
          this.authService.setCustomer(response.customer);
          this.authService.refreshToken().subscribe(x => {
            location.reload();
          });
        }
      });
    }
  }

  async editFriend(entry) {
    let friend = null;
    if (entry) {
      if (entry === this.customer.id) {
        friend = this.customer;
      } else {
        friend = this.customer.my_friends.find(x => x.id == entry);
      }
    }
    const modal = await this.modalController.create({
      component: EditFriendComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        friend: friend
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.loadCustomer();
  }

  async editAddress() {
    const modal = await this.modalController.create({
      component: EditAddressComponent,
      cssClass: 'my-custom-class',
      componentProps: {
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.loadCustomer();
  }

  async getFromGallery() {
    const photo = await this.photoService.getFromGallery();
    await this.upload(photo);
  }

  async upload(photo: CameraPhoto) {
    const base64Data = await this.photoService.readAsBlob(photo);
    const params = {
      body: {
        profile: base64Data
      }
    }
    this.seriesService.sendProfileImage(params).subscribe(success => {
      this.data.uploaded = success.success ? true : false;
      let type = 'success';
      if (success.success) {
        // this.photoService.saveCapturedPhoto(photo);
        // this.toastCtrl.presentToast('Upload successful!');
      } else {
        // this.toastCtrl.presentToast('Error while uploading image, please try again!', 'danger');
        type = 'danger';
      }
      const msg = 'page.profile.upload.' + type;
      this.translationService.get(msg).subscribe(
        value => {
          this.toastCtrl.presentToast(value, type);
        }
      );
      this.loadCustomer();
    });
  }

  async deleteBooking(booking) {
    const today = moment(new Date()).format('YYYY-MM-DD');
    if (!booking.deleted && booking.venue && booking.booking_date >= today) {
      const buttons = [];
        const cancel = {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        };
        buttons.push(cancel);

        const accept = {
          text: 'Okay',
          handler: () => {
            const params = {
              date: booking.booking_date,
              venue: booking.venue.id,
              booking: booking.booking_id,
              body: {
                load_teetimes: false
              }
            }
            this.teetimeService.deletePccTeetime(params).subscribe(data => {
              if (data['msg']) {
                let type = 'danger';
                if (data['msg'] === 'success') {
                  type = 'success';
                }
                this.toastCtrl.presentToast(data['msg'], type);
              }
              this.loadCustomer();
            });
          }
        };
        buttons.push(accept);

        const msg = 'page.teetime.book.delete';
        this.translationService.get(msg).subscribe(
          async (value) => {
            const players = '' + booking.person_name;
            const alert = await this.alertController.create({
              header: value,
              message: '' + players,
              buttons: buttons
            });

            await alert.present();
          }
        );
    }
  }

  validatePCC(): boolean {
    this.isPCCUsernameValid = true;
    this.isPCCPasswordValid = true;

    if (!this.data.pcc.username || this.data.pcc.username.length === 0) {
      this.isPCCUsernameValid = false;
    }

    if (!this.data.pcc.password || this.data.pcc.password.length === 0) {
      this.isPCCPasswordValid = false;
    }

    return this.isPCCUsernameValid && this.isPCCPasswordValid;
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }
}
