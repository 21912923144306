import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de-CH';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Customer, Organization } from 'src/app/services/core/api/models';
import { EspVenue } from 'src/app/services/core/api/models/esp-venue';
import { TeetimesService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { ClubSelectionService, ZeileSelectionService } from 'src/app/shared';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';
import { HomeComponent } from '../../home/home.component';
import { FriendsComponent } from '../friends/friends.component';
 
registerLocaleData(localeDe, 'deCH');

@Component({
  selector: 'gt-zeile',
  templateUrl: './zeile.component.html',
  styleUrls: ['./zeile.component.scss'],
})
export class ZeileComponent implements OnInit {

  public deCHpipe: DatePipe;
  private debugMode = false;
  private adminMode = false;
  isHeaderVisible = false;

  @Input()
  public zeile;

  @Input()
  public selectedDate: string;
  public selectedDateMoment: moment.Moment;

  @Input()
  public selectedVenueObj: EspVenue;

  @Input()
  public selectedClubObj: Organization;

  private customer: Customer;

  slides: any[];
  public bookingPersons = [];
  public bookingDisabled = true;

  get current_time() {
    return moment().unix();
  }

  constructor(
    private teetimeService: TeetimesService,
    private loadingService: LoadingSpinnerService,
    private clubSelectionService: ClubSelectionService,
    private zeileSelectionService: ZeileSelectionService,
    private alertController: AlertController,
    private authService: AuthService,
    private toastCtrl: ToastService,
    public modalController: ModalController,
    private translationService: TranslateService,
    private router: Router
  ) { }

  ngOnInit() {}

  ionViewWillEnter() {
    this.authService.customer$.subscribe(customer => {
      this.customer = customer;
    });
    this.customer = this.authService.getCustomer();

    this.zeileSelectionService.bookingPersons$.subscribe(persons => {
      this.bookingPersons = [];
      if (persons) {
        persons.forEach((element: Customer) => {
          if (this.zeileCanBook(element)) {
            this.bookingPersons.push(element);
          }
        });
      }
      this.bookingDisabled = this.bookingPersons.length <= 0;
    });

    this.zeileSelectionService.teetimeZeile$.subscribe(teetimeZeile => {
      this.setZeileInfo(teetimeZeile);

      const dString1 = '' + this.selectedDate + 'T00:00:00+01:00';
      this.selectedDateMoment = moment(dString1).parseZone();
      this.selectedDate = this.selectedDateMoment.format('dddd, Do MMMM YYYY');
    });
    const teetimeZeile = this.zeileSelectionService.getTeetimeZeile();
    this.setZeileInfo(teetimeZeile);

    this.selectedClubObj = this.clubSelectionService.getClub();
    this.selectedVenueObj = this.clubSelectionService.getVenue();

    this.slides = [
      {
        headerImage: '/assets/imgs/background/maxresdefault.jpg',
        title: this.selectedClubObj.name + ', ' + this.selectedVenueObj.name,
        subtitle: this.selectedDate
      }
    ];

    this.debugMode = this.authService.containRole('MODE_DEBUG');
    this.adminMode = this.authService.containRole('MODE_ADMIN');
  }

  setZeileInfo(teetimeZeile) {
    if (teetimeZeile) {
      this.zeile = teetimeZeile.zeile;
      this.selectedDate = teetimeZeile.selectedDate;
      this.selectedVenueObj = teetimeZeile.selectedVenueObj;
      this.selectedClubObj = teetimeZeile.selectedClubObj;
    }
    if (this.zeileCanBook(this.customer)) {
      this.zeileSelectionService.setBookingPersons([this.customer]);
      // this.bookingPersons.push(this.customer);
    }
  }

  displayAddButton() {
    return this.zeileCanBook({
      id: 0,
      customer_id: '000000'
    });
  }

  zeileCanBook(player) {
    if (!this.zeile || this.zeile.timestamp < this.current_time) {
      return false;
    }

    let numBookable = 0;
    let canBook = false;
    this.zeile.players.forEach(element => {
      if (element.bookable) {
        canBook = true;
        numBookable++;
      }
      if (element.id && element.id === player.id) {
        canBook = false;
      }
    });
    this.bookingPersons.forEach(element => {
      if (element.id && element.id === player.id) {
        canBook = false;
      }
    });
    if (this.bookingPersons.length >= numBookable) {
      canBook = false;
    }
    if (!player.customer_id) {
      canBook = false;
    }
    return canBook;
  }

  golfclub(customer_id) {
    const club = this.clubSelectionService.findClubByCustomerId(customer_id);
    if (!club) {
      return 'page.teetime.friend.noclub';
    }
    return club.name;
  }

  canDelete(player: any) {
    return this.zeileSelectionService.canDelete(player, this.customer);
  }

  async delete(player, zeile) {
    if (zeile.timestamp >= this.current_time && this.canDelete(player)) {
      const buttons = [];
      const cancel = {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
        }
      };
      buttons.push(cancel);

      const accept = {
        text: 'Okay',
        handler: () => {

          const params = {
            date: this.selectedDateMoment.format('YYYY-MM-DD'),
            venue: '' + this.selectedVenueObj.id,
            booking: player.description,
            body: {
              load_teetimes: true
            }
          }
          this.teetimeService.deletePccTeetime(params).subscribe(data => {
            if (data['msg']) {
              let type = 'danger';
              if (data['msg'] === 'success') {
                type = 'success';
                this.removeFromZeile(player.id);
              }
              // this.dismiss(data);
              this.toastCtrl.presentToast(data['msg'], type);
            } 
            this.zeileSelectionService.setTeetimes(data);
          });
        }
      };
      buttons.push(accept);

      const msg = 'page.teetime.book.delete';
      this.translationService.get(msg).subscribe(
        async (value) => {
          const players = '' + player.first_name + ' ' + player.last_name;
          const alert = await this.alertController.create({
            header: value,
            message: '' + players,
            buttons: buttons
          });

          await alert.present();
        }
      );
    }
  }

  removeFromZeile(playerId) {
    this.zeile.players.forEach((value,index)=>{
      if(value.id===playerId) {
        this.zeile.players.splice(index,1);
      }
    });
  }

  dismiss(data) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'timetable': data
    });
  }

  async add() {
    const modal = await this.modalController.create({
      component: FriendsComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        selected: this.bookingPersons,
        zeile: this.zeile
      }
    });

    
    await modal.present();
    // const { data } = await modal.onDidDismiss();

    // this.bookingPersons = [];
    // if (data && data.selected) {
    //   data.selected.forEach(element => {
    //     if (this.zeileCanBook(element.val)) {
    //       this.bookingPersons.push(element.val);
    //     }
    //   });
    // }
    // this.bookingDisabled = this.bookingPersons.length <= 0;
  }

  async book() {
    let canBook = this.bookingPersons.length > 0;

    const buttons = [];
    const cancel = {
      text: 'Cancel',
      role: 'cancel',
      cssClass: 'secondary',
      handler: (blah) => {
      }
    };
    buttons.push(cancel);

    if (canBook) {
      let friendIds = '';
      let index = 0;
      this.bookingPersons.forEach(element => {
        if (index > 0) {
          friendIds += ',';
        }
        friendIds += element.id;
        index++;
      });
      const accept = {
        text: 'Okay',
        handler: () => {
          const params = {
            date: this.selectedDateMoment.format('YYYY-MM-DD'),
            venue: '' + this.selectedVenueObj.id,
            time: this.zeile.time,
            body: {
              friendIds: friendIds
            }
          }
          this.teetimeService.bookPccTeetime(params).subscribe(data => {
            if (data['msg']) {
              let type = 'danger';
              if (data['msg'] === 'success') {
                type = 'success';
              }
              this.toastCtrl.presentToast(data['msg'], type);
            }
            this.zeileSelectionService.setTeetimes(data);
            this.router.navigate(['teetimes']);
            // redirectTo Timetable
          });
        }
      };
      buttons.push(accept);
    }

    const msg = 'page.teetime.book.confirm';
    this.translationService.get(msg).subscribe(
      async (value) => {
        const alert = await this.alertController.create({
          header: value,
          message: '',
          buttons: buttons
        });
    
        await alert.present();
      }
    );
  }

  scrolling(event) {
    setTimeout(()=> {
      this.isHeaderVisible = event.detail.currentY > 230
    });
  }
}
