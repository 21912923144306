import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LoginService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';

@Component({
  selector: 'cs-register-layout-1',
  templateUrl: 'register-layout-1.page.html',
  styleUrls: ['register-layout-1.page.scss'],
})
export class RegisterLayout1Page implements OnInit {
  data: any = {
    "background"          : "assets/imgs/background/Login_wide.jpg",
  };

  private isAGBValid = false;
  private isAnredeValid = true;
  private isEmailValid = true;
  private isFirstnameValid = true;
  private isLastnameValid = true;
  private isLoading = false;
  private success = '';

  private regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  item = {
    'agb': '',
    'anrede': '',
    'firstname': '',
    'lastname': '',
    'email': ''
  };

  constructor(
    private loginService: LoginService,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService,
    private router: Router,
    private iab: InAppBrowser
  ) { }

  ngOnInit(): void {
    this.loadingService.isLoading.subscribe(loading => {
      this.isLoading = loading;
    });
  }

  onRegisterFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.validate()) {
      const params = {
        body: {
          email: this.item.email.replace(' ', ''),
          anrede: this.item.anrede,
          firstname: this.item.firstname.replace(' ', ''),
          lastname: this.item.lastname.replace(' ', '')
        }
      }
      this.loginService.doRegisterPost(params).subscribe(s => {
        this.success = s.error;
        if (s.error === 'ERR') {
          this.toastCtrl.presentToast(s.error_description, 'danger');
        } else {
          // this.displayConfirm();
        }
      });
    }
  }

  onSkipFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.validate()) {
    }
  }

  displayConfirm() {
    this.router.navigate(['confirm']);
  }

  displayAGB() {
    const url = 'https://golf-time.ch/agb';
    const target = '_blank';
    const options = {};
    this.iab.create(url, target, options);
  }

  checkboxChanged() {
    this.isAGBValid = !this.isAGBValid;
  }

  validate(): boolean {
    this.isAnredeValid = true;
    this.isEmailValid = true;
    this.isFirstnameValid = true;
    this.isLastnameValid = true;

    if (!this.item.anrede || this.item.anrede.length === 0) {
      this.isAnredeValid = false;
    }

    if (!this.item.firstname || this.item.firstname.length === 0) {
      this.isFirstnameValid = false;
    }

    if (!this.item.lastname || this.item.lastname.length === 0) {
      this.isLastnameValid = false;
    }

    this.isEmailValid = this.regex.test(this.item.email);

    return this.isAGBValid && this.isEmailValid &&
      this.isFirstnameValid &&
      this.isLastnameValid;
  }
}
