import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/core/api/services';
import { ToastService } from 'src/app/services/toast-service';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { LoadingSpinnerService } from 'src/app/shared/loading-spinner.service';

@Component({
  selector: 'cs-login-layout-2',
  templateUrl: 'login-layout-2.page.html',
  styleUrls: ['login-layout-2.page.scss'],
})
export class LoginLayout2Page implements OnInit {
  data: any = {
    "background"      : "assets/imgs/background/Login_wide.jpg",
    "logo"            : "assets/imgs/logo/iphone_screen_logo_inverse.png",
  };

  private isUsernameValid = true;
  private isPasswordValid = true;
  private errorMessage?: string;

  item = {
    'username': '',
    'password': ''
  };
  loading: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private loginService: LoginService,
    private toastCtrl: ToastService,
    private loadingService: LoadingSpinnerService
  ) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['']);
    }
  }

  onLoginFunc(): void {
    if (event) {
      event.stopPropagation();
    }
    if (this.validate()) {
      this.login();
    }
  }

  onRegisterFunc(): void {
    this.router.navigate(['register']);
  }

  onForgotPassword(): void {
    this.router.navigate(['forgot-password']);
  }

  displayConfirm() {
    this.router.navigate(['confirm']);
  }

  validate(): boolean {
    this.isUsernameValid = true;
    this.isPasswordValid = true;
    if (!this.item.username || this.item.username.length === 0) {
      this.isUsernameValid = false;
    }

    if (!this.item.password || this.item.password.length === 0) {
      this.isPasswordValid = false;
    }

    return this.isPasswordValid && this.isUsernameValid;
  }

  login() {
    this.loading = true;
    const login = {
      username: this.item.username.replace(' ', ''),
      password: this.item.password.replace(' ', '')
    };

    const model = {
      body: login
    };

    const loginUser$ = this.loginService.doLoginPost(model);
    loginUser$.subscribe(
      data => {
        if (data.access_token) {
          this.authService.SetToken(data);
          // this.router.navigate(['']);

          // let subjectId = this.authService.getCurrentUserCustomerId();
          // const email = this.authService.getCurrentUserEmail();

          // send to backend, which login as been logged in to trigger update
          // if (!subjectId) {
          //   subjectId = [0];
          // }

          let currentRoute = sessionStorage.getItem('currentRoute');
          if (!currentRoute || currentRoute === '/login') {
            currentRoute = 'home';
          }
          this.router.navigate([currentRoute]);
          // location.reload();
        } else {
          let msg = 'login failed';
          if (data.error_description) {
            msg = data.error_description;
          }
          this.toastCtrl.presentToast(msg, 'danger');
        }
        this.loading = false;
      },
      e => {
        this.loading = false;
        this.showErrorMessage(e.message);
      }
    );
  }

  showErrorMessage(message: string): void {
    this.errorMessage = message;
  }
  clearErrorMessage(): void {
    this.errorMessage = null;
  }
}
