/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EventAttendee } from '../models/event-attendee';
import { EventEntity } from '../models/event-entity';
import { Organization } from '../models/organization';

@Injectable({
  providedIn: 'root',
})
export class EventsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPccClubList
   */
  static readonly GetPccClubListPath = '/api/golftime/pcc/clubs';

  /**
   * Returns a list of clubs.
   *
   * Returns a list of clubs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPccClubList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccClubList$Response(params?: {
  }): Observable<StrictHttpResponse<{ 'clubs'?: Array<Organization> }>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.GetPccClubListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'clubs'?: Array<Organization> }>;
      })
    );
  }

  /**
   * Returns a list of clubs.
   *
   * Returns a list of clubs
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPccClubList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccClubList(params?: {
  }): Observable<{ 'clubs'?: Array<Organization> }> {

    return this.getPccClubList$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'clubs'?: Array<Organization> }>) => r.body as { 'clubs'?: Array<Organization> })
    );
  }

  /**
   * Path part for operation getPccEventList
   */
  static readonly GetPccEventListPath = '/api/golftime/pccs/{club}/events';

  /**
   * Returns a list of events by club.
   *
   * Returns a list of events by club
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPccEventList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccEventList$Response(params: {

    /**
     * all events from year
     */
    year?: number;

    /**
     * all events from year page
     */
    page?: number;

    /**
     * all events from year limit
     */
    limit?: number;
    club: string;
  }): Observable<StrictHttpResponse<{ 'events'?: Array<EventEntity> }>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.GetPccEventListPath, 'get');
    if (params) {
      rb.query('year', params.year, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('club', params.club, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'events'?: Array<EventEntity> }>;
      })
    );
  }

  /**
   * Returns a list of events by club.
   *
   * Returns a list of events by club
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPccEventList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccEventList(params: {

    /**
     * all events from year
     */
    year?: number;

    /**
     * all events from year page
     */
    page?: number;

    /**
     * all events from year limit
     */
    limit?: number;
    club: string;
  }): Observable<{ 'events'?: Array<EventEntity> }> {

    return this.getPccEventList$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'events'?: Array<EventEntity> }>) => r.body as { 'events'?: Array<EventEntity> })
    );
  }

  /**
   * Path part for operation getPccResultList
   */
  static readonly GetPccResultListPath = '/api/golftime/pccs/{event}/events/{prize}/attendees';

  /**
   * Returns pet inventories by status.
   *
   * Returns a map of status codes to quantities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPccResultList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccResultList$Response(params: {

    /**
     * sorting results by
     */
    sort?: string;

    /**
     * all players by page
     */
    page?: number;

    /**
     * limit number of players to display
     */
    limit?: number;
    event: string;
    prize: string;
  }): Observable<StrictHttpResponse<{ 'players'?: Array<EventAttendee> }>> {

    const rb = new RequestBuilder(this.rootUrl, EventsService.GetPccResultListPath, 'get');
    if (params) {
      rb.query('sort', params.sort, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.path('event', params.event, {});
      rb.path('prize', params.prize, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ 'players'?: Array<EventAttendee> }>;
      })
    );
  }

  /**
   * Returns pet inventories by status.
   *
   * Returns a map of status codes to quantities
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPccResultList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPccResultList(params: {

    /**
     * sorting results by
     */
    sort?: string;

    /**
     * all players by page
     */
    page?: number;

    /**
     * limit number of players to display
     */
    limit?: number;
    event: string;
    prize: string;
  }): Observable<{ 'players'?: Array<EventAttendee> }> {

    return this.getPccResultList$Response(params).pipe(
      map((r: StrictHttpResponse<{ 'players'?: Array<EventAttendee> }>) => r.body as { 'players'?: Array<EventAttendee> })
    );
  }

}
