import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'gt-rank',
  templateUrl: 'rank.component.html',
  styleUrls: ['rank.component.scss'],
})
export class RankComponent implements OnChanges {

  @Input() rank: string;

  constructor() {}

  ngOnChanges(changes: { [propKey: string]: any }) {
    this.rank = changes['rank'].currentValue;
  }
}
